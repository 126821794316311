@import "../variable";
@import "../mixins";

.topbar {
  -moz-transition: color .2s ease-out 0;
  -webkit-transition: color .2s ease-out 0;
  background: @topbar-bg-color-light;
  height: @topbar-height;
  transition: color .2s ease-out 0;
  position: relative;
  &::after {
    clear: both;
    content: '';
    display: block;
  }
  .header-left {
    float: left;
    .topnav {
      float: left;
      height: @topbar-height;
      margin: 0;
      .menutoggle {
        background: none;
        color: @topbar-color-dark;
        cursor: pointer;
        float: left;
        font-size: 22px;
        height: @topbar-height;
        position: relative;
        text-decoration: none !important;
        width: @sidebar-collapsed-width;
        i {
          display: inline-block;
          margin: 14px 0 0 13px;
          &:before {
            -webkit-font-smoothing: antialiased;
            content: "r";
            font-family: "line-icons" !important;
            font-style: normal !important;
            font-variant: normal !important;
            font-weight: normal !important;
            line-height: 1;
            speak: none;
            text-transform: none !important;
          }
        }
      }
      .menu__handle {
        background: rgba(0, 0, 0, 0);
        border: none;
        height: 24px;
        left: 18px;
        outline: none;
        padding: 0;
        position: absolute;
        top: 11px;
        width: 20px;
        z-index: 2000;
        &::before {
          -moz-transform-origin: 50% 50%;
          -moz-transform: translate3d(0, -5px, 0);
          -webkit-transform-origin: 50% 50%;
          -webkit-transform: translate3d(0, -5px, 0);
          transform-origin: 50% 50%;
          transform: translate3d(0, -5px, 0);
          background: @topbar-bg-color-dark;
          content: '';
          height: 2px;
          left: 0;
          position: absolute;
          top: 50%;
          width: 100%;
          .transition(transform, .3s);
        }
        &::after {
          -moz-transform-origin: 50% 50%;
          -moz-transform: translate3d(0, 5px, 0);
          -webkit-transform-origin: 50% 50%;
          -webkit-transform: translate3d(0, 5px, 0);
          background: @topbar-bg-color-dark;
          content: '';
          height: 2px;
          left: 0;
          position: absolute;
          top: 50%;
          transform-origin: 50% 50%;
          transform: translate3d(0, 5px, 0);
          width: 100%;
          .transition(transform, .3s);
        }
        span {
          height: 2px;
          left: 0;
          overflow: hidden;
          position: absolute;
          text-indent: 200%;
          top: 12px;
          .transition(opacity, .25s);
          width: 100%;
        }
      }
      .mmenu {
        .dropdown {
          a {
            color: #656565;
          }
        }
        .dropdown-menu {
          border: 1px solid #efefef;
          box-shadow: none;
          left: auto;
          margin-top: 0 !important;
          padding: 0;
          a {
            color: #656565;
          }
          .withoutdesc {
            display: block;
            margin-top: 0;
            padding: 15px 20px;
            text-align: left;
            text-transform: none;
            width: 100%;
            ul {
              li {
                padding: 3px 10px;
                &:hover {
                  background-color: #f5f5f5 !important;
                  color: #262626;
                  text-decoration: none;
                }
                &:focus {
                  background-color: #f5f5f5 !important;
                  color: #262626;
                  text-decoration: none;
                }
              }
            }
            li {
              &:last-child {
                border-bottom: 0 solid #fff;
              }
            }
          }
        }
        .dropdown-menu > li > a {
          padding: 6px 15px;
        }
        .navbar-nav > li > .dropdown-menu {
          margin-top: 1px;
        }
        i {
          color: #BFBFBF;
        }
        .form-control {
          border: 1px solid #efefef;
          margin-top: 10px;
        }
        .btn {
          margin: 10px 0 20px;
        }
        video {
          height: auto;
          max-width: 100%;
        }
        iframe {
          max-width: 100%;
        }
        embed {
          max-width: 100%;
        }
        object {
          max-width: 100%;
        }
        a {
          &:hover {
            text-decoration: none;
          }
        }
        .mmenu-content.withdesc {
          a {
            &:after {
              color: #CFCFCF;
              content: attr(data-description);
              display: block;
              font-size: 11px;
              font-weight: 400;
              line-height: 0;
              margin: 10px 0 15px;
              text-transform: uppercase;
            }
          }
        }
        .dropdown-submenu {
          position: relative;
          &:hover > .dropdown-menu {
            display: block;
          }
          &:hover > a {
            after {
              border-left-color: #fff;
            }
          }
        }
        .dropdown-submenu > .dropdown-menu {
          left: 100%;
          margin-left: 0;
          margin-top: 0 !important;
          top: 0;
          .border-radiuses(0, 6px, 6px, 6px)
        }
        .dropdown-submenu > a {
          &:after {
            border-color: transparent;
            border-left-color: #cccccc;
            border-style: solid;
            border-width: 5px 0 5px 5px;
            content: " ";
            display: block;
            float: right;
            height: 0;
            margin-right: -2px;
            margin-top: 5px;
            width: 0;
          }
        }
        .dropdown-submenu.pull-left {
          float: none;
        }
        .dropdown-submenu.pull-left > .dropdown-menu {
          left: -100%;
          margin-left: 10px;
          .border-radiuses(6px, 0, 6px, 6px)
        }
        p {
          color: #656565;
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-size: 13px;
        }
        .half {
          left: auto !important;
          right: auto !important;
          width: 50%;
          float: none;
          text-align: left;
        }
        .container {
          position: relative;
        }
        .nav.navbar-right {
          .dropdown-menu {
            left: auto;
            right: 0;
          }
        }
        .mmenu-content {
          padding: 15px 25px;
        }
        .dropdown.mmenu-fw {
          .dropdown-menu {
            left: 0;
            margin-top: 0 !important;
            right: 0;
            .box-shadow(0, 3px, 3px, 0.18);
          }
        }
        .title {
          border-bottom: 1px solid #efefef;
          font-family: 'Lato', 'Open Sans';
          font-size: 14px;
          margin-top: 15px;
          padding-bottom: 10px;
          text-transform: uppercase;
          strong {
            font-family: 'Lato', 'Open Sans';
            font-weight: 900;
          }
        }
        ul {
          list-style: none;
        }
      }
      .sidebar-collapsed {
        .mmenu {
          .dropdown.mmenu-fw {
            .dropdown-menu {
              left: @sidebar-collapsed-width;
              right: 0;
            }
          }
        }
      }
      .mmenu-dark.mmenu {
        .dropdown {
          .dropdown-menu {
            background-color: #202226;
            border: none;
            color: @topbar-color-light;
            .title {
              border-bottom: 1px solid rgba(255, 255, 255, 0.05);
            }
            hr {
              border-top: 1px solid rgba(255, 255, 255, 0.05);
            }
            .withoutdesc {
              ul {
                li {
                  padding: 3px 10px;
                  &:hover {
                    background-color: rgba(255, 255, 255, 0.05) !important;
                    color: #fff;
                  }
                  &:focus {
                    background-color: rgba(255, 255, 255, 0.05) !important;
                    color: #fff;
                  }
                }
              }
            }
          }
        }
        .dropdown-menu {
          a {
            color: #DADADA;
          }
        }
        .mmenu-content.withdesc {
          a {
            &:after {
              color: #6A6A6A;
            }
          }
        }
        p {
          color: #959595;
        }
        .form-control {
          background-color: #3D4043;
          border: 1px solid #232323;
          color: #D3D3D3;
        }
      }
      ul > li {
        .dropdown-menu {
          li {
            position: relative;
          }
          .dropdown-menu {
            left: 100%;
            margin-left: 6px;
            top: -2px;
          }
        }
        .dropdown-menu > li > a {
          &:hover {
            background: #319DB5 !important;
            color: #ffffff !important;
          }
        }
      }
    }
    .topnav > ul {
      float: left;
      height: @topbar-height;
      margin: 0;
      padding: 0;
    }
    .topnav > ul > li {
      float: left;
      height: 100%;
      margin: 0;
      .dropdown-menu {
        -moz-border-radius: 0 0 3px 3px;
        -webkit-border-radius: 0 0 3px 3px;
        border-radius: 0 0 3px 3px;
        margin-top: 2px;
        .border-radiuses(0, 0, 3px, 3px);
        i {
          display: none;
        }
      }
      .dropdown-menu > li > a {
        font-size: 13px;
        padding: 6px 10px;
        &:hover {
          background: #319DB5;
          color: @topbar-color-light;
        }
      }
    }
    .topnav > ul > li > a {
      border-radius: 0;
      color: @topbar-color-gray;
      font-size: 14px;
      height: 100%;
      padding: 13px 19px 13px;
      .border-radius(0);
      &::before {
        -moz-transform: scale(0);
        -moz-transition: -moz-transform 0.3s;
        -webkit-transform: scale(0);
        -webkit-transition: -webkit-transform 0.3s;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        top: 0;
        transform: scale(0);
        transition: transform 0.3s;
        width: 100%;
      }
      i {
        font-size: 14px;
        margin-right: 6px;
      }
      i.icons-arrows-06 {
        display: inline-block;
        font-size: 9px;
        margin-bottom: -1px;
        padding-left: 7px;
        vertical-align: bottom;
      }
      .badge {
        margin-left: 10px;
      }
    }
    .topnav > ul.nav-icons > li > a {
      padding: 0;
      &:hover {
        padding: 0;
      }
    }
    ul.nav-icons {
      li {
        padding: 10px 20px;
        a {
          background: none !important;
          font-size: 19px;
          width: 20px;
          &:before {
            display: none !important;
          }
        }
        span {
          color: #484E55;
          display: block;
          font-size: 20px;
          padding-top: 4px;
        }
        span.icon-user-unfollow {
          background: none !important;
          font-size: 19px;
          width: 20px;
        }
        span.icon-user-following {
          background: none !important;
          font-size: 19px;
          width: 20px;
        }
      }
    }
  }
  .header-right {
    float: right;
    .nav > li {
      float: left;
    }
    .navbar-nav > li > a {
      height: @topbar-height;
      line-height: normal;
      &:hover {
        background: none !important;
      }
      &:focus {
        background: none !important;
      }
    }
    .header-menu > li > a > i {
      display: block;
      float: left;
      margin-top: 14px;
    }
    .header-menu > li > a > span {
      &:not(.badge) {
        display: block;
        float: left;
        line-height: 48px;
      }
    }
    .header-menu {
      .username {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .badge-header {
        font-size: 12px;
        padding: 2px 5px;
        position: relative;
        right: 8px;
        top: -8px;
        .border-radius(8px);
      }
      .dropdown-menu > li > a.pull-right {
        clear: none;
      }
      .header-icon {
        display: inline;
        height: 22px;
        max-width: 100%;
      }
      .dropdown-menu {
        border-top-left-radius: 1px !important;
        border-top-right-radius: 1px !important;
        left: auto;
        margin: 0;
        max-width: 290px !important;
        min-width: 170px !important;
        padding: 0;
        right: 0;
        top: 53px;
        width: 280px;
        &:after {
          border-bottom: 6px solid @topbar-color-dark;
          border-left: 6px solid rgba(0, 0, 0, 0);
          border-right: 6px solid rgba(0, 0, 0, 0);
          content: "";
          display: inline-block;
          position: absolute;
          right: 17px;
          top: -6px;
        }
        .dropdown-header {
          background: @topbar-bg-color-dark;
          color: @topbar-color-gray;
          font-family: 'Open Sans';
          font-size: 15px;
          padding: 8px 15px;
          &:hover {
            background: @topbar-bg-color-dark;
            color: @topbar-color-gray;
            cursor: default;
          }
          p {
            color: #E4E4E4 !important;
            color: @topbar-color-light;
            font-family: 'Open Sans';
            font-size: 13px;
            margin: 0;
          }
        }
        ul {
          list-style-type: none;
          padding-left: 0;
        }
        li {
          background: none;
          padding: 0;
          &:hover {
            background: none;
          }
          a {
            &:hover {
              background: #f5f5f5;
            }
          }
          ul {
            li {
              padding: 12px 10px 6px 10px;
              &:hover {
                background: #eaeaea;
              }
              a {
                display: block;
                width: 100%;
                i {
                  width: 30px;
                }
                &:hover {
                  text-decoration: none;
                }
              }
              .dropdown-time {
                color: #777777;
                display: inline-block;
                float: right;
                font-size: 11px;
                text-align: right;
              }
            }
          }
        }
        a {
          color: #121212;
          &:hover {
            background: none;
          }
        }
        .dropdown-footer {
          background: #F8F8F8;
          border-top: 1px solid #E2E2E2;
          color: #121212;
          font-family: 'Open Sans';
          font-size: 12px;
          padding: 5px;
          a {
            padding: 3px 5px;
            &:hover {
              background: none;
            }
          }
        }
      }
      .dropdown-menu > li > a.pull-left {
        clear: none;
      }
      #notifications-header > a > i {
        color: @topbar-color-gray;
        display: inline-block;
        font-size: 20px;
      }
      #messages-header > a > i {
        color: @topbar-color-gray;
        display: inline-block;
        font-size: 20px;
      }
      #messages-header {
        ul {
          li {
            img {
              height: 30px;
              margin-top: -5px;
              .border-radius(50%);
            }
          }
        }
        .dropdown-body {
          p {
            font-size: 12px;
            font-style: italic;
            margin: 5px 0 5px;
          }
        }
      }
      #user-header {
        margin-right: 10px;
        a {
          color: @topbar-color-gray;
        }
        img {
          float: left;
          margin-right: 6px;
          margin-top: 6px;
          width: 36px;
          .border-radius(50%);
        }
        li {
          a {
            &:hover {
              background-color: #eaeaea;
              text-decoration: none;
            }
          }
        }
        .dropdown-menu {
          width: 150px !important;
          li {
            a {
              background: @topbar-bg-color-dark;
              color: #959FA9;
              display: block;
              font-size: 13px;
              padding: 10px 12px;
              &:hover {
                color: @topbar-color-light;
              }
              i {
                padding-right: 8px;
                position: absolute;
              }
              span {
                padding-left: 25px;
              }
            }
          }
          li.dropdown-footer {
            background-color: #E9E9E9;
            padding: 0;
            a {
              i {
                position: relative;
              }
            }
          }
          .dropdown-footer {
            a {
              color: #575757;
              display: inline-block;
              font-size: 16px;
              padding: 8px;
              text-align: center;
              width: 56px;
              &:hover {
                background-color: #dcdcdc;
              }
              i {
                padding-right: 0;
              }
            }
          }
        }
      }
      #user-header > a > i {
        font-size: 19px;
        padding-right: 5px;
      }
      #language-header {
        margin-right: 10px;
        a {
          color: @topbar-color-gray;
          i {
            font-size: 19px;
            padding-right: 7px;
          }
        }
        li {
          a {
            &:hover {
              text-decoration: none;
            }
          }
        }
        .dropdown-menu {
          max-width: 140px !important;
          min-width: 70px !important;
          width: 130px !important;
          li {
            a {
              display: block;
              font-size: 13px;
              padding: 10px 12px;
              i {
                padding-right: 8px;
                position: absolute;
              }
              img {
                width: 22px;
              }
              span {
                padding-left: 10px;
              }
            }
          }
          &:after {
            border-bottom: none;
            border-left: none;
            border-right: none;
          }
        }
      }
      #notifications-header {
        .glyph-icon {
          font-size: 22px;
          margin-top: 2px;
        }
      }
      .notification {
        display: inline-block;
        height: 8px;
        left: 10px;
        position: absolute;
        top: 7px;
        width: 8px;
        .border-radius(50%);
      }
      .tp-icon {
        background: @topbar-bg-color-light;
        border-color: #fff;
        color: #333333;
        font-size: 16px;
        height: @topbar-height;
        padding: 12px 15px;
        .transition(all, .2s, ease-out)
      }
      .tp-icon + .dropdown-menu {
        &:after {
          right: 17px;
        }
      }
      .chat-icon {
        border-right: 0;
      }
      .dropdown-menu-usermenu {
        background: @topbar-bg-color-dark;
        min-width: 200px;
      }
      .dropdown-menu-head {
        ul {
          border-top: 0;
          border: 1px solid #dddddd;
          padding: 0 10px;
        }
        li {
          a {
            color: #333333;
            opacity: 1;
            padding: 0;
            &:hover {
              background-color: @topbar-bg-color-light !important;
              color: #428BCA;
              text-decoration: none;
            }
          }
        }
      }
      .btn-group {
        margin-bottom: 0;
      }
      .nav > li {
        float: left;
      }
      .navbar-nav {
        margin: 0;
        .open {
          .dropdown-menu > li > a {
            padding: 3px 5px;
          }
          .dropdown-menu {
            max-width: 290px !important;
            min-width: 170px !important;
            position: absolute !important;
            width: 280px !important;
          }
        }
      }
    }
    .header-menu.navbar-nav {
      float: left !important;
      margin: 0;
      .dropdown-menu {
        background-color: @topbar-bg-color-light;
        border: 1px solid rgba(0, 0, 0, 0.15) !important;
        position: absolute !important;
        .box-shadow(3px, 3px, 0, 0.03)
      }
    }
    .header-menu.navbar-nav > li > a {
      line-height: @topbar-height;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
  .nav {
    .open > a {
      background: none !important;
      border: none !important;
      &:hover {
        background: none !important;
        border: none !important;
      }
      &:focus {
        background: none !important;
        border: none !important;
      }
    }
  }
}

.topbar.c-light .header-left .topnav > ul > li > a, .topbar.c-light .header-left .topnav .menutoggle, .topbar.c-light .header-right .header-menu #notifications-header > a > i, .topbar.c-light .header-right .header-menu #messages-header > a > i, .topbar.c-light .header-right .header-menu #user-header a {
  color: #EBEBEB !important;
}

.topbar .header-left .topnav > ul > li > a:hover, .topbar .header-left .topnav > ul:not(.nav-icons) > li > a:focus, .topbar .header-left .topnav > ul:not(.nav-icons) > li.open > a, .topbar .header-left .topnav > ul:not(.nav-icons) > li.active > a {
  background: none;
  color: #298598;
  padding: 13px 19px 13px;
}

.topbar .header-left .topnav > ul > li.active > a::before, .topbar .header-left .topnav > ul > li.open > a::before, .topbar .header-left .topnav > ul > li > a:hover::before, .topbar .header-left .topnav > ul > li > a:focus::before {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  width: 100%;
}

.menu__handle {
  span {
    background: @topbar-bg-color-dark;
  }
}

.topbar .header-left .topnav .mmenu .nav, .topbar .header-left .topnav .mmenu .collapse, .topbar .header-left .topnav .mmenu .dropup, .topbar .header-left .topnav .mmenu .dropdown {
  position: static;
}

.topbar.topbar-condensed {
  .header-left {
    .topnav > ul > li > a {
      font-size: 11px;
      padding: 17px 10px 12px;
    }
    .topnav {
      .logopanel2 {
        display: none;
      }
    }
  }
  .header-right {
    .header-menu {
      #notifications-header {
        display: none;
      }
    }
  }
}

body {
  &:not(.sidebar-collapsed) {
    .topbar {
      .header-left {
        .topnav {
          .menutoggle {
            &:hover {
              .menu__handle {
                &::before {
                  -moz-transform: translate3d(0, -6px, 0);
                  -webkit-transform: translate3d(0, -6px, 0);
                  transform: translate3d(0, -6px, 0);
                }
                &::after {
                  -moz-transform: translate3d(0, 6px, 0);
                  -webkit-transform: translate3d(0, 6px, 0);
                  transform: translate3d(0, 6px, 0);
                }
              }
            }
          }
        }
      }
    }
  }
}

.fixed-topbar {
  .topbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 240px;
    z-index: 801;
    z-index: 300;
    .box-shadow(0, 3px, 3px, .05);
  }
}

.sidebar-collapsed.fixed-topbar {
  .topbar {
    left: @sidebar-collapsed-width;
  }
}

@media screen and (min-width: 1025px) {
  .sidebar-collapsed {
    .topbar {
      .header-left {
        .topnav {
          .menu__handle {
            background: rgba(0, 0, 0, 0);
            left: 14px;
            top: 12px;
            &::before {
              -moz-transform: rotate3d(0, 0, 1, 45deg);
              -webkit-transform: rotate3d(0, 0, 1, 45deg);
              background: #ffffff;
              transform: rotate3d(0, 0, 1, 45deg);

            }
            &::after {
              -moz-transform: rotate3d(0, 0, 1, -45deg);
              -webkit-transform: rotate3d(0, 0, 1, -45deg);
              background: #ffffff;
              transform: rotate3d(0, 0, 1, -45deg);
            }
            span {
              opacity: 0;
            }
          }
        }
      }
    }
    .menu__handle {
      span {
        background: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .topbar.topbar-condensed {
    .header-left {
      .topnav > ul {
        left: @sidebar-collapsed-width;
      }
      .topnav > ul > li {
        left: -@sidebar-collapsed-width;
      }
    }
  }

  .topbar {
    .large-dropdown-menu {
      min-width: 160px;
    }
    .header-left {
      .nav-icons {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .sidebar-hover {
    .topbar {
      .header-right {
        .header-menu {
          #language-header {
            display: none;
          }
        }
      }
    }
    .topnav {
      .logopanel2 {
        width: 150px !important;
      }
    }
  }
}

@media screen and (max-width: 610px) {
  .topbar {
    .header-right {
      .header-menu {
        #notifications-header {
          .dropdown-menu {
            left: -50px;
          }
        }
        #messages-header {
          .dropdown-menu {
            left: -120px;
          }
        }
        .dropdown-menu {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .sidebar-hover {
    .topbar {
      .header-right {
        .header-menu {
          #notifications-header {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .topbar {
    .header-right {
      .header-menu {
        #language-header {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .topbar {
    .header-right {
      .header-menu {
        #messages-header {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .topbar {
    .header-right {
      .header-menu {
        #notifications-header {
          display: none;
        }
      }
    }
  }
}
