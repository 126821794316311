@import "../variable";
@import "../mixins";

.widget {
  margin-bottom: 20px;
  padding: 0 10px 10px
}

/* TODO LIST */
.todo-list {
  margin: 0;
  padding: 0;
  li {
    background: #f8f8f8;
    border-left: 2px solid rgba(0, 0, 0, 0.04);
    list-style: none;
    margin-bottom: 4px;
    padding: 10px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    &:hover {
      background: #F5F5F5;
      .todo-tags {
        display: none;
      }
      .todo-options {
        display: inline;
        position: absolute;
        right: 14px;
        top: 10px;
      }
    }
    .editable-buttons {
      display: inline-block;
      margin-left: 7px !important;
      i {
        font-size: 18px;
      }
    }
    .span-check {
      height: 22px;
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 5px;
      width: 26px;
    }
    .todo-task {
      border-bottom: 1px dashed rgba(0, 0, 0, 0) !important;
      font-size: 12px;
      &:hover {
        border-bottom: 1px dashed rgba(0, 0, 0, 0.2) !important;
      }
    }
    .due-date {
      span {
        border-bottom: 1px dashed rgba(0, 0, 0, 0) !important;
        font-size: 12px;
      }
    }
    .todo-date {
      display: block;
      font-size: 12px;
      line-height: 1.5;
      margin-left: 40px;
      .due-date {
        float: none;
      }
      .completed-date {
        float: left;
        margin-right: 20px;
      }
    }
    .done {
      .todo-date {
        .due-date {
          float: right;
        }
      }
    }
    .todo-options {
      display: none;
      font-size: 16px;
      a {
        color: #888888;
      }
      .todo-delete {
        &:hover {
          color: #C75757;
        }
      }
    }
    .todo-tags {
      display: inline;
      font-family: "Open Sans";
      left: auto;
      position: absolute;
      right: 9px;
      top: 6px;
    }
    .editable-container.editable-inline {
      margin-top: -4px;
      overflow: visible;
    }
    .editable-unsaved {
      font-weight: normal !important;
    }
    .editableform {
      .btn.btn-sm {
        margin-right: 0 !important;
        margin-top: 0px;
        padding: 7px 12px !important;
        i {
          font-size: 12px !important;
        }
      }
    }
  }
  li.done {
    opacity: .5;
    .todo-task {
      text-decoration: line-through;
    }
    .todo-tags {
      display: inline !important;
    }
    .todo-options {
      display: none !important;
    }
  }
  li > span {
    display: inline-block;
    height: 24px;
    line-height: 26px;
    margin-top: 5px;
    max-width: 72%;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  li > span.todo-task {
    overflow: hidden;
  }
  li.high {
    border-left: 2px solid #C75757;
    border-right: none;
  }
  li.medium {
    border-left: 2px solid #FF9122;
    border-right: none;
  }
  li.low {
    border-left: 2px solid #F3D261;
    border-right: none;
  }
  li.new-task {
    input[type=checkbox] + label {
      &::before {
        left: 3px;
      }
    }
    input[type="checkbox"].checkbox-square:checked + label {
      &::after {
        left: 4px;
      }
    }
  }
}

/* WEATHER WIDGET  */

.widget-weather {
  border: none !important;
  .border-radius(0);
  .panel-header {
    border: none !important;
    h3 {
      color: @weather-color !important;
    }
    i {
      display: inline-block;
      margin-top: -5px;
      &:before {
        font-size: 35px;
      }
    }
  }
  .panel-content {
    border: none !important;
  }
  .weather {
    background-color: #319DB5;
    margin-bottom: -151px;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    .weather-forecast {
      .icon-0 {
        &:before {
          content: ":";
        }
      }
      .icon-1 {
        &:before {
          content: "p";
        }
      }
      .icon-2 {
        &:before {
          content: "S";
        }
      }
      .icon-3 {
        &:before {
          content: "Q";
        }
      }
      .icon-4 {
        &:before {
          content: "S";
        }
      }
      .icon-5 {
        &:before {
          content: "W";
        }
      }
      .icon-6 {
        &:before {
          content: "W";
        }
      }
      .icon-7 {
        &:before {
          content: "W";
        }
      }
      .icon-8 {
        &:before {
          content: "W";
        }
      }
      .icon-9 {
        &:before {
          content: "I";
        }
      }
      .icon-10 {
        &:before {
          content: "W";
        }
      }
      .icon-11 {
        &:before {
          content: "I";
        }
      }
      .icon-12 {
        &:before {
          content: "I";
        }
      }
      .icon-13 {
        &:before {
          content: "I";
        }
      }
      .icon-14 {
        &:before {
          content: "I";
        }
      }
      .icon-15 {
        &:before {
          content: "W";
        }
      }
      .icon-16 {
        &:before {
          content: "I";
        }
      }
      .icon-17 {
        &:before {
          content: "W";
        }
      }
      .icon-18 {
        &:before {
          content: "U";
        }
      }
      .icon-19 {
        &:before {
          content: "Z";
        }
      }
      .icon-20 {
        &:before {
          content: "Z";
        }
      }
      .icon-21 {
        &:before {
          content: "Z";
        }
      }
      .icon-22 {
        &:before {
          content: "Z";
        }
      }
      .icon-23 {
        &:before {
          content: "Z";
        }
      }
      .icon-24 {
        &:before {
          content: "E";
        }
      }
      .icon-25 {
        &:before {
          content: "E";
        }
      }
      .icon-26 {
        &:before {
          content: "3";
        }
      }
      .icon-27 {
        &:before {
          content: "a";
        }
      }
      .icon-28 {
        &:before {
          content: "A";
        }
      }
      .icon-29 {
        &:before {
          content: "a";
        }
      }
      .icon-30 {
        &:before {
          content: "A";
        }
      }
      .icon-31 {
        &:before {
          content: "6";
        }
      }
      .icon-32 {
        &:before {
          content: "1";
        }
      }
      .icon-33 {
        &:before {
          content: "6";
        }
      }
      .icon-34 {
        &:before {
          content: "1";
        }
      }
      .icon-35 {
        &:before {
          content: "W";
        }
      }
      .icon-36 {
        &:before {
          content: "1";
        }
      }
      .icon-37 {
        &:before {
          content: "S";
        }
      }
      .icon-38 {
        &:before {
          content: "S";
        }
      }
      .icon-39 {
        &:before {
          content: "S";
        }
      }
      .icon-40 {
        &:before {
          content: "M";
        }
      }
      .icon-41 {
        &:before {
          content: "W";
        }
      }
      .icon-42 {
        &:before {
          content: "I";
        }
      }
      .icon-43 {
        &:before {
          content: "W";
        }
      }
      .icon-44 {
        &:before {
          content: "a";
        }
      }
      .icon-45 {
        &:before {
          content: "S";
        }
      }
      .icon-46 {
        &:before {
          content: "U";
        }
      }
      .icon-47 {
        &:before {
          content: "S";
        }
      }
      li {
        border-left: 1px solid #f2fbfb;
        border-left: 1px solid rgba(242, 251, 251, 0.2);
        float: left;
        list-style-type: none;
        width: 20%;
        a {
          color: @weather-color;
          display: block;
          font-size: 13px;
          font-weight: 400;
          height: 100%;
          padding: 10px 3px 0;
          text-align: center;
          text-transform: uppercase;
          &:hover {
            background: #60c9cb;
            background: rgba(242, 251, 251, 0.2);
          }
        }
        a.active {
          background: #60c9cb;
          background: rgba(242, 251, 251, 0.2);
        }
        &:first-child {
          a {
            border-left: 0 none;
          }
        }
      }
      strong {
        display: block;
        margin-bottom: 5px;
        text-align: center !important;
      }
      .icon-weather {
        background: url(images/icons/weather-icons.png) 0 0 no-repeat;
        height: 24px;
        margin: 0;
        width: 24px;
      }
      .icon-weather-2 {
        background-position: 0 -24px;
      }
      .icon-weather-3 {
        background-position: 0 -48px;
      }
      .icon-weather-4 {
        background-position: 0 -72px;
      }
      .icon-weather-5 {
        background-position: 0 -96px;
      }
    }
    .weather-highlighted {
      height: 105px;
      padding: 10px 25px 12px 35px;
      text-align: left;
      span.today-temp {
        color: @weather-color;
      }
      .icon-weather {
        background: url(images/icons/weather-main.png) no-repeat;
        float: left;
        height: 57px;
        margin-top: 10px;
        width: 76px;
      }
      .inner {
        float: right;
        margin-top: -10px;
        width: 100px;
      }
      strong {
        color: @weather-color;
        display: block;
        font-size: 12px;
        font-size: 46px;
        font-weight: 300;
        line-height: 1.2em;
      }
      .weather-item {
        display: none;
      }
      .weather-item.active {
        display: block;
        margin-top: 10px;
      }
    }
    .weather-location {
      border-bottom: 1px solid #f2fbfb;
      border-bottom: 1px solid rgba(242, 251, 251, 0.2);
      border-top: 1px solid #f2fbfb;
      border-top: 1px solid rgba(242, 251, 251, 0.2);
      padding: 14px 17px 16px 21px;
      text-align: left;
      .weather-search-form {
        float: right;
        position: relative;
        width: 130px;
        &:before {
          color: @weather-color;
          content: "\e090";
          font-family: 'Simple-Line-Icons' !important;
          left: auto;
          opacity: 0.5;
          position: absolute;
          right: 7px;
          top: 5px;
        }
        #city-form {
          &::-webkit-input-placeholder {
            color: @weather-color;
          }
          &:-moz-placeholder {
            color: @weather-color;
          }
          &::-moz-placeholder {
            color: @weather-color;
          }
          &:-ms-input-placeholder {
            color: @weather-color;
          }
        }
      }
      input.weather-search-field {
        -webkit-box-shadow: none;
        background: #319ba1;
        background: rgba(96, 96, 96, 0.4);
        border: 0 none;
        box-shadow: none;
        color: @weather-color;
        font-size: 12px;
        font-style: italic;
        font-weight: 300;
        height: 24px;
        padding: 0 15px 0 5px;
        width: 100%;
        .border-radius(2px);
        &:focus {
          border: 0 none;
        }
      }
      .ie8 {
        input.weather-search-field {
          line-height: 17px;
        }
      }
      input.weather-search-submit {
        background: none;
        height: 19px;
        left: 0;
        padding: 2px 4px;
        position: absolute;
        top: 0;
        width: 19px;
      }
      strong {
        color: @weather-color;
        display: inline-block;
        font-family: 'Lato';
        font-size: 18px;
        font-weight: 100;
        height: 21px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
        white-space: nowrap;
        width: 140px;
      }
    }
  }
  .weather.panel-content {
    padding: 0 !important;
  }
}

.widget-weather [class^="icon-"] {
  &:before {
    color: @weather-color;
    font-family: weather;
    font-size: 40px;
    font-style: normal;
    font-weight: normal;
    line-height: .9;
    margin-left: 0;
  }
}

.widget-weather [class*="icon-"] {
  &:before {
    color: @weather-color;
    font-family: weather;
    font-size: 40px;
    font-style: normal;
    font-weight: normal;
    line-height: .9;
    margin-left: 0;
  }
}

.widget-weather .weather .weather-highlighted span.weather-currently, .widget-weather .weather .weather-highlighted span.one-days-text, .widget-weather .weather .weather-highlighted span.two-days-text, .widget-weather .weather .weather-highlighted span.three-days-text, .widget-weather .weather .weather-highlighted span.four-days-text {
  color: @weather-color;
  font-size: 11px !important;
}

.weather-highlighted {
  span {
    color: @weather-color;
    display: block;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.2em;
  }
}

.weather-forecast {
  span {
    display: block;
    margin-bottom: 5px;
    text-align: center !important;
  }
}

/* CALENDAR WIDGET */

.widget_calendar {
  .multidatepicker {
    .ui-widget-content {
      -moz-box-shadow: none !important;
      -o-box-shadow: none !important;
      -webkit-box-shadow: none !important;
      background: none !important;
      border: none !important;
      box-shadow: none !important;
      display: none;
      margin-top: 0;
      padding: 0;
      .ie8 {
        #date_departure {
          display: none !important;
        }
      }
      .ui-widget {
        &:active {
          outline: none;
        }
      }
      .ui-widget-header {
        background: none !important;
        border-bottom: 1px solid #ea8164;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        margin: 0 -10px;
        padding: 0;
        position: relative;
        .ui-icon {
          display: block;
          overflow: hidden;
          text-indent: -99999px;
        }
        .ui-state-hover {
          background: #eeeeee;
          color: #88361f;
        }
        .ui-state-focus {
          background: #eeeeee;
          color: #88361f;
        }
        .ui-datepicker-prev {
          background-color: transparent !important;
          background-image: url(../images/widgets/pagination.png) !important;
          background-position: 5px 5px !important;
          background-repeat: no-repeat !important;
          border-radius: 50%;
          border: 2px solid @calendar-bg-light;
          cursor: pointer;
          height: 24px;
          left: 19px;
          position: absolute;
          top: 25px;
          width: 24px;
        }
        .ui-datepicker-next {
          background-color: transparent !important;
          background-image: url(../images/widgets/pagination.png) !important;
          background-position: -45px 5px !important;
          background-repeat: no-repeat !important;
          border-radius: 50%;
          border: 2px solid @calendar-bg-light;
          cursor: pointer;
          height: 24px;
          left: 19px;
          left: auto;
          position: absolute;
          right: 19px;
          top: 25px;
          width: 24px;
        }
        .ui-datepicker-title {
          color: @calendar-color-light;
          font-size: 14px;
          font-weight: 700;
          height: 74px;
          line-height: 74px;
          text-align: center;
          text-transform: uppercase;
        }
      }
      table {
        border-collapse: collapse;
        width: 100%;
        th {
          padding: .7em .3em;
          text-align: center;
        }
        td {
          padding: 0;
          .ui-state-focus {
            padding: 0;
            padding: 0;
          }
          span {
            border-radius: 50%;
            color: @calendar-color-light;
            display: block;
            font-size: 12px;
            font-weight: 400;
            height: 24px;
            line-height: 24px;
            margin: 2px auto;
            padding: 0;
            text-align: center;
            text-decoration: none;
            width: 24px;
            &:hover {
              background: #f5f5f5;
              color: #3C3C3C;
            }
          }
          a {
            border-radius: 50%;
            color: @calendar-color-light;
            display: block;
            font-size: 12px;
            font-weight: 400;
            height: 24px;
            line-height: 24px;
            margin: 2px auto;
            padding: 0;
            text-align: center;
            text-decoration: none;
            width: 24px;
            &:hover {
              background: #f5f5f5;
              color: #3C3C3C;
            }
          }
          .ui-state-hover {
            a {
              color: @calendar-color-dark;
              text-decoration: none;
              &:hover {
                color: @calendar-color-dark;
                text-decoration: none;
              }
            }
          }
          .ui-state-disabled {
            cursor: default !important;
          }
          .ui-state-active {
            a {
              color: @calendar-color-dark;
              text-decoration: none;
              &:link {
                color: @calendar-color-dark;
                text-decoration: none;
              }
              &:visited {
                color: @calendar-color-dark;
                text-decoration: none;
              }
            }
          }
        }
        td.ui-datepicker-today {
          a {
            background: @calendar-bg-light !important;
            color: @calendar-color-dark;
          }
        }
        td.ui-state-highlight {
          a {
            background: @calendar-bg-light !important;
            color: @calendar-color-dark;
            font-weight: 700;
          }
        }
        td.ui-datepicker-today.ui-state-highlight {
          a {
            background: @calendar-bg-light !important;
            color: @calendar-color-dark;
            font-weight: 700;
          }
        }
        td.ui-datepicker-other-month {
          span {
            color: @calendar-color-dark;
          }
        }
        thead {
          th {
            color: @calendar-color-light;
            font-size: 10px;
            font-weight: 700;
            padding: 30px 0 10px;
            text-transform: uppercase;
          }
        }
        tbody {
          td {
            padding: 0;
            text-align: center;
          }
          td.ui-state-disabled {
            padding: 0;
            text-align: center;
          }
        }
      }
    }
  }
}

/* WIDGET SLIDER */
.widget_slider {
  padding: 20px 40px;
  text-align: center;
  position: relative;
  p {
    text-align: center
  }

}

/* WIDGET SLIDER IMAGE */
.widget-slider-img {
  overflow: hidden;
  padding: 0;
  position: relative;
  .text {
    bottom: 0;
    color: #ffffff;
    font-size: 16px;
    position: absolute;
    right: 12px;
    z-index: 4;
  }
  .slick-dots {
    bottom: 5px;
  }
  .slick-slider {
    margin-bottom: 0;
  }
  .slide {
    &:before {
      background-image: url('../images/gradient-big.png');
      background-repeat: repeat-x;
      bottom: 0;
      content: " ";
      height: 70px;
      left: 0;
      position: absolute;
      right: 0;
      z-index: 2;
    }
    p {
      font-family: 'Lato', arial;
      font-weight: 100;
    }
  }
  .text-top {
    .text {
      bottom: auto;
      top: 0;
    }
    .slide {
      &:before {
        background-image: url('../images/gradient-big.png');
        bottom: auto;
        filter: FlipV;
        height: 30px;
        top: 0;
        -ms-filter: "FlipV";
        .scale(-1);
      }
    }
  }
}

/* WiIDGET PROGRESS BAR */
.widget-progress-bar {
  .title {
    color: rgba(0, 0, 0, 0.6);
    float: left;
    font-family: 'Open Sans';
    font-size: 13px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-top: 0;
    opacity: 1;
    text-transform: none;
  }
  .number {
    color: rgba(0, 0, 0, 0.5);
    float: right;
    font-family: 'Open Sans';
    font-size: 11px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-top: 0;
    margin-top: 2px;
    opacity: 1;
    text-transform: none;
  }
  .progress {
    background: rgba(0, 0, 0, 0.1);
    height: 6px;
  }
}

/* WIDGET NEWS */
.widget-news.panel-content {
  padding: 0 !important;
}

.widget-news {
  .message-item {
    border-bottom: 1px solid #ECEBEB !important;
    border: none;
    display: block;
    margin: 0;
    padding: 10px 15px 0;
  }
  .pull-left {
    i {
      font-size: 24px;
      display: block;
      margin-top: 7px;
    }
  }
}

.media-body {
  p {
    height: 27px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* WIDGET TABLE */
.widget-table {
  padding: 0 !important;
  table {
    margin-bottom: 0;
    thead {
      border-top: 1px solid #ededed;
      th {
        text-align: left;
        text-transform: none;
      }
    }
    tbody {
      th {
        text-align: left;
      }
    }
  }
}

/* WIDGET FULL WIDTH */
.panel-content.widget-full {
  overflow: hidden;
  padding: 0 !important;
}

.widget-full {
  .tabs-linetriangle {
    .nav.nav-tabs > li > a {
      font-weight: 400;
      text-transform: none;
    }
    .nav-tabs {
      a {
        span {
          text-align: center;
        }
        span.title {
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
  }
}

/* WIDGET STOCK */
.stock1 {
  .tab-content {
    min-height: 316px;
  }
}

.widget-stock {
  .tab_right {
    .nav-tabs {
      li {
        a {
          opacity: 0.5;
          padding: 15px 25px;
          text-align: left;
          width: 200px;
          span {
            display: block;
          }
        }
        &:hover {
          a {
            opacity: 0.7;
          }
        }
      }
      li.active {
        a {
          opacity: 1;
        }
      }
      a {
        span.title {
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
  }
  .title-stock {
    h1 {
      float: left;
      padding: 10px;
    }
    span {
      float: right;
      font-size: 18px;
      padding: 10px;
    }
  }
  .company-info {
    color: #8D8D8D;
    display: block;
    padding-top: 15px;
  }
}

.stock2 {
  .tab-content {
    min-height: 442px;
  }
}

/* WIDGET SMALL */
.widget-small {
  border: none !important;
  max-height: 160px;
  overflow: hidden;
  padding: 0 !important;
  .border-radius(0);
  .title {
    padding: 10px;
    h1 {
      font-size: 20px !important;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
      opacity: 0.8;
      padding-top: 5px;
    }
    span {
      font-family: 'Lato', arial, sans-serif;
      font-weight: 900;
      opacity: 0.7;
    }
  }
  .highcharts-container {
    margin-top: -98px;
  }
}

/* WIDGET CASH IN HAND */
.widget-cash-in-hand {
  float: left;
  .cash {
    border-right: 2px dashed rgba(0, 0, 0, 0.1);
    float: left;
    padding: 5px 20px;
    text-align: center;
    &:last-child {
      border-right: none;
    }
    .number {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }
    .txt {
      font-size: 12px;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

@media and

(
max-width:

800
px

)
{
.widget-cash-in-hand {
  .row {
    .cash {
      width: 33.3333%;
      &:nth-child(3) {
        border-right: none;
      }
      .number {
        font-size: 14px;
      }
      .txt {
        font-size: 11px;
      }
    }
  }
}

}

/* WIDGET CLOCK */
.jquery-clock {
  -webkit-background-size: cover;
  background-image: url(../images/clock/clock.png);
  background-size: cover;
  height: 600px;
  list-style: none;
  margin: auto;
  padding: 0;
  position: relative;
  width: 600px;
  .jquery-clock-sec {
    -webkit-background-size: contain;
    background-image: url(../images/clock/sechand.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    left: 45%;
    position: absolute;
    top: 0;
    width: 10%;
    z-index: 3;
  }
  .jquery-clock-min {
    -webkit-background-size: contain;
    background-image: url(../images/clock/minhand.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    left: 45%;
    position: absolute;
    top: 0;
    width: 10%;
    z-index: 2;
  }
  .jquery-clock-hour {
    -webkit-background-size: contain;
    background-image: url(../images/clock/hourhand.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    left: 45%;
    position: absolute;
    top: 0;
    width: 10%;
    z-index: 1;
  }
  .jquery-clock-pin {
    background: #fff;
    border-radius: 50%;
    height: 3%;
    left: 50%;
    margin: -1.5%;
    position: absolute;
    top: 50%;
    width: 3%;
    z-index: 10;
  }
  .jquery-clock-transitions {
    .transition(all, 1s, cubic-bezier(.01, 1.81, 0, .88))
  }
}

.jquery-clock.medium {
  height: 300px;
  width: 300px;
}

.jquery-clock.small {
  height: 200px;
  width: 200px;
}

.jquery-clock.white {
  background-image: url(../images/clock/clock-white.png);
  .jquery-clock-sec {
    background-image: url(../images/clock/sechand-white.png);
    z-index: 3;
  }
  .jquery-clock-min {
    background-image: url(../images/clock/minhand-white.png);
    z-index: 2;
  }
  .jquery-clock-hour {
    background-image: url(../images/clock/hourhand-white.png);
    z-index: 1;
  }
  .jquery-clock-pin {
    background-color: #000;
  }
}

/* WIDGET INFOBOX */
.widget-infobox {
  float: left;
  margin-top: -8px;
  .infobox {
    background: #ffffff;
    float: left;
    margin-right: 10px;
    min-width: 165px;
    padding: 8px;
    text-align: center;
    margin-bottom: 10px;
  }
  .left {
    float: left;
    padding-left: 0;
    padding-right: 10px;
    i {
      color: #ffffff;
      font-size: 23px;
      text-align: center;
      &::before {
        display: inline-block;
        height: 48px;
        margin-top: 0;
        padding: 13px;
        width: 48px;
        .border-radius(50%);
      }
    }
  }
  .right {
    float: left;
    padding-left: 0;
    padding-right: 10px;
    .pull-left {
      font-family: 'Lato', 'Open Sans', Arial;
      font-weight: 900;
      font-size: 22px;
    }
    .pull-right {
      font-family: 'Lato', 'Open Sans', Arial;
      font-weight: 100;
      font-size: 14px;
      margin-top: 7px;
    }
  }
  .left[class*="icon-"] {
    font-size: 21px;
  }
  .txt {
    float: left;
    min-width: 80px;
  }
}

/* WIDGET INFO */
.widget-info {
  padding-top: 20px;
  .left {
    float: left;
    padding-left: 10px;
    width: 60px;
    i {
      color: #ffffff;
      font-size: 23px;
      text-align: center;
      &::before {
        display: inline-block;
        height: 48px;
        margin-top: 0;
        padding: 13px;
        width: 48px;
        .border-radius(50%);
      }
    }
    i[class*="icon-"] {
      font-size: 22px;
    }
  }
  .right {
    float: left;
    padding-left: 10px;
    .number {
      font-family: 'Lato', 'Open Sans', Arial;
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 2px;
    }
    .text {
      color: #A3A3A3;
      font-size: 12px;
      text-align: left;
      text-transform: uppercase;
    }
  }
}

/* WIDGET QUICKLINK */
.quick-link {
  float: left;
  padding: 5px 15px;
  width: 80px;
  .border-radius(5px);
  &:hover {
    background: #F7F7F7;
    cursor: pointer;
    .text {
      color: #646464;
    }
  }
  .icon {
    margin: auto;
    text-align: center;
    width: 60px;
    i {
      color: #ffffff;
      font-size: 27px;
      padding-right: 0 !important;
      text-align: center;
      float: none !important;
      &::before {
        display: inline-block;
        height: 55px;
        margin-top: 0;
        padding: 13px;
        width: 55px;
        .border-radius(50%);
      }
    }
  }
  .text {
    color: #A3A3A3;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/* WIDGET USER */
.widget-member {
  border: 1px solid #EBEBEB;
  margin-bottom: 10px;
  margin-top: 15px;
  padding: 15px 15px 5px 15px;
  .transition(all, 300ms, ease-in-out);
  img {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .member-name {
    margin-bottom: 5px !important;
  }
  .member-job {
    color: #ADADAD;
    margin-bottom: 6px;
    margin-top: 0;
  }
  .col-xlg-6 {
    font-size: 12px;
    p {
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.widget-member2 {
  border: 1px solid #EBEBEB;
  margin-bottom: 10px;
  margin-top: 15px;
  padding: 15px 15px 5px 15px;
  padding: 15px;
  .transition(all, 300ms, ease-in-out);
  img {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .member-name {
    margin-bottom: 15px !important;
    margin-bottom: 5px !important;
    span {
      padding-left: 8px;
      text-transform: none;
    }
  }
  .member-job {
    color: #ADADAD;
    margin-bottom: 6px;
    margin-top: 0;
  }
  .col-xlg-6 {
    p {
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .col-lg-3 {
    padding-right: 0;
  }
  .col-xlg-4 {
    p {
      font-size: 12px;
    }
  }
}

.widget-member3 {
  border: 1px solid #EBEBEB;
  margin-bottom: 10px;
  margin-top: 15px;
  padding: 15px 15px 5px 15px;
  .transition(all, 300ms, ease-in-out);
  img {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .member-name {
    margin-bottom: 5px !important;
  }
  .member-job {
    color: #ADADAD;
    margin-bottom: 6px;
    margin-top: 0;
  }
  .col-xlg-12 {
    font-size: 12px;
    p {
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.profil-name-heading {
  color: #ffffff;
  position: absolute;
  top: 40px;
  width: 100%;
  div {
    text-align: center;
    float: none;
  }
}

#modal-icons i {
  cursor: pointer !important;
}

.widget-profil-img-center {
  border: 10px solid #ffffff;
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .3);
  margin-top: -40px;
}

.hover-effect {
  overflow: hidden;
  .hover-img {
    .transition(all, 0.2s, linear);
  }
  &:hover {
    .hover-img {
      -webkit-filter: contrast(120%);
      filter: contrast(120%);
    }
  }
}

/* WIDGET MAP */
.widget-map {
  height: 440px;
  overflow: hidden;
  .col-md-3 {
    background-color: rgba(32, 34, 38, 0.5);
    color: #CDCDCD !important;
    height: 440px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: absolute;
    right: 0;
    top: 0;
  }
  .panel-header {
    border: none !important;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .panel-content {
    border: none !important;
    height: 440px;
    overflow: hidden;
  }
  .amcharts-chart-div {
    a {
      display: none !important;
    }
  }
  .ammapObjectList {
    ul {
      list-style-type: none;
      li {
        &:not(.paginate_button) {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
    }
    li {
      list-style-type: none;
    }
  }
  .listdiv {
    height: 250px;
    overflow: hidden;
  }
}

.maximized.widget-map {
  height: 100%;
  .panel-content {
    height: 100%;
  }
}

.maximized {
  #map {
    height: 100%;
    width: 100%;
  }
}

.bg-dark {
  .ammapObjectList {
    a {
      color: #D0D0D0;
      padding: 2px 15px;
      &:hover {
        background: rgba(32, 34, 38, 0.5);
        color: #319DB5;
      }
    }
  }
}

.map-marker {
  margin-left: -8px;
  margin-top: -8px;
}

.map-marker.map-clickable {
  cursor: pointer;
}

.pulse-map {
  background-color: #716f42;
  border: 5px solid #f7f14c;
  height: 20px;
  position: absolute;
  width: 20px;
  z-index: 10;
  .border-radius(30px);
}

.dot-map {
  -moz-animation-iteration-count: infinite;
  -moz-animation: pulsemap 3s ease-out;
  -moz-border-radius: 60px;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation: pulsemap 3s ease-out;
  -webkit-border-radius: 60px;
  background: transparent;
  border-radius: 60px;
  border: 10px solid #fff601;
  height: 50px;
  left: -15px;
  opacity: 0;
  position: absolute;
  top: -15px;
  width: 50px;
  z-index: 1;
}

@-moz-keyframes pulsemap {
  0% {
    -moz-transform: scale(0);
    opacity: 0
  }
  25% {
    -moz-transform: scale(0);
    opacity: .1
  }

  50% {
    -moz-transform: scale(0.1);
    opacity: .3
  }
  75% {
    -moz-transform: scale(0.5);
    opacity: .5
  }
  100% {
    -moz-transform: scale(1);
    opacity: 0
  }
}

@-webkit-keyframes pulsemap {
  0% {
    -webkit-transform: scale(0);
    opacity: 0
  }
  25% {
    -webkit-transform: scale(0);
    opacity: .1
  }
  50% {
    -webkit-transform: scale(0.1);
    opacity: .3
  }
  75% {
    -webkit-transform: scale(0.5);
    opacity: .5
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0
  }
}