@import "../variable";
@import "../mixins";

.ui-timepicker-div {
  dl {
    border-width: 1px 0 0 0;
    border: 1px solid #cfcfcf;
    margin: 0;
    padding: 15px 10px;
    text-align: left;
    text-align: left;
    dt {
      clear: left;
      clear: left;
      float: left;
      float: left;
      padding: 0 0 0 5px;
      padding: 0 0 0 5px;
    }
    dd {
      margin: 0 10px 10px 40%;
      margin: 0 10px 20px 40%;
    }
  }
  td {
    font-size: 90%;
    font-size: 90%;
  }
  .slider-wrapper {
    background: #e5e5e5;
    position: relative;
  }
  .sliderv-wrapper {
    background: #e5e5e5;
    position: relative;
  }
  .ui-slider {
    position: relative;
    text-align: left;
    .ui-slider-handle {
      -moz-border-radius: 20px;
      -ms-touch-action: none;
      -o-border-radius: 20px;
      -webkit-border-radius: 20px;
      background: #fff;
      border-radius: 20px;
      border: 3px solid @primary;
      cursor: default;
      height: 1.5em;
      margin-top: -3px;
      outline: 0;
      position: absolute;
      touch-action: none;
      width: 1.5em;
      z-index: 2;
      &:before {
        -moz-border-radius: 10px;
        -o-border-radius: 10px;
        -webkit-border-radius: 10px;
        background-color: @primary;
        border-radius: 10px;
        content: '';
        height: 7px;
        left: 4px;
        position: absolute;
        top: 4px;
        width: 7px;
        z-index: 2;
      }
    }
    .ui-slider-range {
      background-color: @primary;
      background-position: 0 0;
      border: 0;
      display: block;
      font-size: .7em;
      position: absolute;
      z-index: 1;
    }
  }
  .ui-slider.ui-state-disabled {
    .ui-slider-handle {
      filter: inherit;
    }
    .ui-slider-range {
      filter: inherit;
    }
  }
  .ui-slider-horizontal {
    height: .5em;
    .ui-slider-handle {
      margin-left: -.6em;
      top: -.3em;
    }
    .ui-slider-range {
      height: 100%;
      top: 0;
    }
    .ui-slider-range-min {
      left: 0;
    }
    .ui-slider-range-max {
      right: 0;
    }
  }
  .ui-widget-header {
    background: #1B1E25;
    font-size: 15px;
    line-height: 27px;
    padding: 10px;
  }
}

.ui-tpicker-grid-label {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.ui-timepicker-rtl {
  direction: rtl;
  dl {
    padding: 0 5px 0 0;
    text-align: right;
    dt {
      clear: right;
      float: right;
    }
    dd {
      margin: 0 40% 10px 10px;
    }
  }
}

.ui-datepicker {
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, .1);
  -o-box-shadow: 0 0 4px rgba(0, 0, 0, .1);
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .1);
  background: #fff;
  border: 1px solid #cfcfcf;
  box-shadow: 0 0 4px rgba(0, 0, 0, .1);
  color: #666666;
  display: none;
  font-size: 14px;
  margin-top: 8px;
  position: relative;
  text-align: center;
  width: 18em;
  z-index: 10;
  a {
    color: #404040;
    text-align: center;
  }
  .ui-state-disabled {
    span {
      color: #dbdbdb;
      &:hover {
        background: 0;
      }
    }
  }
  .ui-datepicker-header {
    background: #1B1E25;
    background: #1B1E25;
    border-bottom: 1px solid #cfcfcf;
    font-size: 15px;
    font-size: 15px;
    line-height: 27px;
    line-height: 27px;
    padding: 10px;
    padding: 10px;
    position: relative;
  }
  .ui-datepicker-prev {
    color: #D3D3D3;
    cursor: pointer;
    display: block;
    font-size: 14px;
    height: 34px;
    left: 2px;
    position: absolute;
    text-decoration: none;
    top: 20.5%;
    width: 34px;
  }
  .ui-datepicker-next {
    color: #D3D3D3;
    cursor: pointer;
    display: block;
    font-size: 14px;
    height: 34px;
    position: absolute;
    right: 2px;
    text-decoration: none;
    top: 20.5%;
    width: 34px;
  }
  .ui-datepicker-title {
    color: #E9E9E9;
    font-family: 'Lato';
    font-weight: 100;
    margin: 0 2.3em;
    text-align: center;
    select {
      font-size: 1em;
      margin: 1px 0;
    }
  }
  select.ui-datepicker-month-year {
    width: 100%;
  }
  select.ui-datepicker-month {
    width: 49%;
  }
  select.ui-datepicker-year {
    width: 49%;
  }
  table {
    border-collapse: collapse;
    font-size: .9em;
    margin: 0 0 .4em;
    width: 100%;
  }
  th {
    border: 0;
    font-weight: bold;
    padding: .5em .3em;
    text-align: center;
  }
  td {
    border: 0;
    padding: 2px 5px;
    span {
      display: block;
      padding: .25em;
      text-align: center;
      text-decoration: none;
      &:hover {
        background: #f5f5f5;
        color: #3C3C3C;
      }
    }
    a {
      display: block;
      padding: .25em;
      text-align: center;
      text-decoration: none;
      &:hover {
        background: #f5f5f5;
        color: #3C3C3C;
      }
    }
  }
  .ui-state-highlight {
    color: #fff;
    font-weight: 700;
    font-weight: 700;
  }
}

.ui-datepicker-today {
  a {
    background: #B3C5C7 !important;
    color: #616060;
    font-weight: 700;
    font-weight: 700;
    &:hover {
      background: @primary !important;
      color: #fff;
      font-weight: 700;
    }
  }
}

.ui-datepicker-current-day {
  .ui-state-active {
    background: @primary !important;
    color: #fff;
    font-weight: 700;
  }
}

.cal-widget {
  .ui-datepicker {
    margin-top: 0;
    width: 100%;
    &:before {
      display: none;
    }
  }
}

.ui-datepicker.ui-datepicker-multi {
  width: auto;
}

.ui-datepicker-multi {
  .ui-datepicker-group {
    float: left;
    table {
      margin: 0 auto .4em;
      width: 95%;
    }
  }
}

.ui-datepicker-multi-2 {
  .ui-datepicker-group {
    width: 50%;
  }
}

.ui-monthpicker {
  .ui-datepicker-header {
    margin-bottom: 3px;
  }
}

.ui-timepicker-div dl .ui_tpicker_hour, .ui-timepicker-div dl .ui_tpicker_minute, .ui-timepicker-div dl .ui_tpicker_second, .ui-timepicker-div dl .ui_tpicker_millisec {
  -moz-border-radius: 4px;
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 4px;
  background-color: #F0F0F0;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  top: 6px;
  webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.ui-datepicker-buttonpane {
  background: #ECECEC;
  border-top: 1px solid #ECECEC;
  padding: 10px;
  button {
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
    background-color: #2B2E33;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4;
    margin-right: 10px;
    padding: 7px 24px 8px 24px;
    transition: border .25s linear, color .25s linear, background-color .25s linear;
    &:hover {
      background-color: #1F2225;
      border-color: #1F2225;
      color: #fff;
    }
  }
  button.ui-datepicker-current {
    background-color: #687386;
    &:hover {
      background-color: #5d6778;
    }
  }
}

.ui-datepicker-inline {
  width: 100%;
}

.datepicker table tr td span {
  text-align: center;
}

/* Bootstrap Datepicker */
.datepicker {
  border-radius: 0;
  table {
    tr {
      td.today {
        background-color: #E3E6E8 !important;
        background-image: none !important;
        border-color: #E3E6E8 !important;
        border-radius: 0 !important;
        color: #121212 !important;
        &:hover {
          background-color: #E3E6E8 !important;
          background-image: none !important;
          border-color: #E3E6E8 !important;
          border-radius: 0 !important;
          color: #121212 !important;
        }
      }
      td.today.disabled {
        background-color: #E3E6E8 !important;
        background-image: none !important;
        border-color: #E3E6E8 !important;
        border-radius: 0 !important;
        color: #121212 !important;
        &:hover {
          background-color: #E3E6E8 !important;
          background-image: none !important;
          border-color: #E3E6E8;
          border-radius: 0 !important;
          color: #121212 !important;
        }
      }
      td {
        border-radius: 0;
      }
      th {
        border-radius: 0;
      }
      td.selected {
        background-color: #999999 !important;
        background-image: none !important;
        border-color: #555555 !important;
        color: #ffffff !important;
        text-shadow: none !important;
        &:hover {
          background-color: #999999 !important;
          background-image: none !important;
          border-color: #555555 !important;
          color: #ffffff !important;
          text-shadow: none;
        }
      }
      td.selected.disabled {
        background-color: #999999 !important;
        background-image: none !important;
        border-color: #555555 !important;
        color: #ffffff !important;
        text-shadow: none !important;
        &:hover {
          background-color: #999999 !important;
          background-image: none !important;
          border-color: #555555 !important;
          color: #ffffff !important;
          text-shadow: none !important;
        }
      }
    }
  }
}

.input-daterange {
  input {
    text-align: left;
  }
  .input-group-addon {
    background: #ffffff !important;
    border: none !important;
  }
}

.datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover:hover, .datepicker table tr td span.active:focus, .datepicker table tr td span.active:hover:focus, .datepicker table tr td span.active.disabled:focus, .datepicker table tr td span.active.disabled:hover:focus, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:hover.active, .open .dropdown-toggle.datepicker table tr td span.active, .open .dropdown-toggle.datepicker table tr td span.active:hover, .open .dropdown-toggle.datepicker table tr td span.active.disabled, .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #2b2e33 !important;
  border-color: #2b2e33 !important;
  border-radius: 0 !important !important;
  color: #ffffff !important;
}

.datepicker table tr td.today:hover, .datepicker table tr td.today:hover:hover, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today.disabled:hover:hover, .datepicker table tr td.today:focus, .datepicker table tr td.today:hover:focus, .datepicker table tr td.today.disabled:focus, .datepicker table tr td.today.disabled:hover:focus, .datepicker table tr td.today:active, .datepicker table tr td.today:hover:active, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today.active, .datepicker table tr td.today:hover.active, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled:hover.active, .open .dropdown-toggle.datepicker table tr td.today, .open .dropdown-toggle.datepicker table tr td.today:hover, .open .dropdown-toggle.datepicker table tr td.today.disabled, .open .dropdown-toggle.datepicker table tr td.today.disabled:hover {
  background-color: #DDE1E3 !important;
  background-image: none !important;
  border-color: #DDE1E3 !important;
  border-radius: 0 !important;
  color: #121212 !important;
}

.datepicker table tr td.active:hover, .datepicker table tr td.active:hover:hover, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover:hover, .datepicker table tr td.active:focus, .datepicker table tr td.active:hover:focus, .datepicker table tr td.active.disabled:focus, .datepicker table tr td.active.disabled:hover:focus, .datepicker table tr td.active:active, .datepicker table tr td.active:hover:active, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.active, .datepicker table tr td.active:hover.active, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled:hover.active, .open .dropdown-toggle.datepicker table tr td.active, .open .dropdown-toggle.datepicker table tr td.active:hover, .open .dropdown-toggle.datepicker table tr td.active.disabled, .open .dropdown-toggle.datepicker table tr td.active.disabled:hover {
  background-color: #2b2e33 !important;
  border-color: #2b2e33 !important;
  border-radius: 0 !important;
  color: #ffffff !important;
}

.datepicker-dropdown.datepicker-orient-bottom:before, .datepicker-dropdown.datepicker-orient-bottom:after {
  top: auto;
}