@import "../variable";
@import "../mixins";

/**** Search ****/
.morphsearch {
  background: @search-bg-dark;
  min-height: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 10000;
  .transition(all, .5s, ease-in-out);
  .gn-search:focus {
    &::-webkit-input-placeholder {
      color: transparent;
    }
  }
  input[type="search"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .dummy-column {
    &:nth-child(2) {
      margin: 0 5%;
    }
    h2 {
      color: #c2c2c2;
      font-size: 1em;
      font-weight: 800;
      letter-spacing: 1px;
      padding: .5em 0;
      text-transform: uppercase;
    }
  }
  .round {
    border-radius: 50%;
  }
  .dummy-media-object {
    background: rgba(118, 117, 128, 0.05);
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin: .3em 0;
    padding: .75em;
    &:hover {
      background: rgba(118, 117, 128, 0.1);
      h3 {
        color: #ffffff;
      }
    }
    img {
      display: inline-block;
      margin: 0 10px 0 0;
      vertical-align: middle;
      width: 50px;
    }
    h3 {
      color: rgba(145, 145, 145, 0.7);
      display: inline-block;
      font-size: 1em;
      font-weight: 700;
      margin: 0;
      vertical-align: middle;
      width: calc(100% - 70px);
    }
  }
  .user-column {
    .dummy-media-object {
      img {
        width: 35px;
      }
    }
  }
}

.morphsearch.open {
  min-height: 100%;
  opacity: 1;
  right: 0;
  top: 0;
  visibility: visible;
  width: 100%;
  .morphsearch-form {
    height: 160px;
    width: 80%;
  }
  .morphsearch-input {
    font-family: 'Lato', arial !important;
    font-weight: 100;
    font-size: 7em;
  }
  .morphsearch-submit {
    -webkit-transform: translate3d(-30px, -50%, 0) scale3d(1, 1, 1);
    -webkit-transition-delay: .5s;
    -webkit-transition: opacity 0.3s, -moz-transform .3s;
    -moz-transform: translate3d(-30px, -50%, 0) scale3d(1, 1, 1);
    -moz-transition-delay: .5s;
    -moz-transition: opacity 0.3s, -moz-transform .3s;
    transform: translate3d(-30px, -50%, 0) scale3d(1, 1, 1);
    transition-delay: .5s;
    transition: opacity 0.3s, transform .3s;
    opacity: 0.2;
    pointer-events: auto;

  }
  .morphsearch-close {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
    pointer-events: auto;
    .transition(all, .3s, linear);
  }
  .morphsearch-content {
    height: auto;
    opacity: 1;
    overflow: visible;
    pointer-events: auto;
    .transition(opacity, .3s, linear);

  }
  .dummy-column {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
    transform: translate3d(0, 0, 0);
    &:first-child {
      .transition-delay(.2s);
    }
    &:nth-child(2) {
      .transition-delay(.4s);
    }
    &:nth-child(3) {
      .transition-delay(.6s);
    }
  }
}

.morphsearch-form {
  -webkit-transform: translate3d(0, 3em, 0);
  height: 40px;
  margin: 0 auto;
  position: relative;
  transform: translate3d(0, 3em, 0);
  width: 100%;
  .transition-duration(.3s);
}

.morphsearch-input {
  -webkit-transition: font-size .3s cubic-bezier(0.7, 0, 0.3, 1);
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: .8em;
  font-weight: 700;
  height: 100%;
  padding: 0 10% 0 10px;
  transition: font-size .3s cubic-bezier(0.7, 0, 0.3, 1);
  width: 100%;
  &::-ms-clear {
    display: none;
  }
  &::-webkit-input-placeholder {
    color: #626262;
  }
  &:-moz-placeholder {
    color: #626262;
  }
  &::-moz-placeholder {
    color: #626262;
  }
  &:-ms-input-placeholder {
    color: #626262;
  }
  &:focus {
    outline: none;
  }
}

.morphsearch.hideInput {
  .morphsearch-input {
    -webkit-transition: color .3s;
    color: transparent;
    transition: color .3s;
  }
}

.morphsearch-submit {
  -webkit-transform: translate3d(-30px, -50%, 0) scale3d(0, 0, 1);
  background-size: 100%;
  background: transparent url(../images/magnifier.svg) no-repeat center center;
  border: none;
  height: 80px;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-indent: 100px;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translate3d(-30px, -50%, 0) scale3d(0, 0, 1);
  width: 80px;
  &:focus {
    outline: none;
  }
}

.morphsearch-close {
  -webkit-transform: scale3d(0, 0, 1);
  cursor: pointer;
  height: 36px;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 1em;
  text-indent: 100%;
  top: 1em;
  transform: scale3d(0, 0, 1);
  width: 36px;
  &::before {
    background: #ffffff;
    content: '';
    height: 100%;
    left: 50%;
    opacity: .2;
    position: absolute;
    top: 0;
    width: 2px;
    .border-radius(3px);
    .rotation(45deg);
  }
  &::after {
    background: #ffffff;
    content: '';
    height: 100%;
    left: 50%;
    opacity: .2;
    position: absolute;
    top: 0;
    width: 2px;
    .border-radius(3px);
    .rotation(-45deg);
  }
}

.morphsearch-close:hover.morphsearch-close {
  &::before {
    opacity: 1;
  }
  &::after {
    opacity: 1;
  }
}

.morphsearch-content {
  background: @search-bg-dark;
  color: #333333;
  height: 0;
  margin-top: 4.5em;
  opacity: 0;
  overflow: hidden;
  padding: 0 10.5%;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.dummy-column {
  -webkit-transform: translate3d(0, 100px, 0);
  float: left;
  opacity: 0;
  padding: 0 0 6em;
  transform: translateY(100px);
  width: 30%;
  .transition(all, .3s, linear);

}

.dummy-media-object {
  &:focus {
    background: rgba(118, 117, 128, 0.1);
  }
}

.overlay {
  -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  width: 100%;
  .transition(opacity, .5s, linear);
}

.morphsearch.open ~ .overlay {
  opacity: 1;
}

@media screen and (max-width: 1200px) {
  .morphsearch {
    width: 80%;
    top: 10%;
    right: 10%
  }
}

@media screen and (max-width: 1000px) {
  .morphsearch-input {
    padding: 0 25% 0 10px;
  }

  .morphsearch.open {
    .morphsearch-input {
      font-size: 3em;
    }
    .morphsearch-submit {
      height: 40px;
      width: 40px;
    }
    .morphsearch-form {
      height: 80px;
    }
  }

  .morphsearch {
    .dummy-column {
      float: none;
      padding: 0 0 2em;
      width: auto;
      &:nth-child(2) {
        margin: 0;
      }
    }
  }
}
