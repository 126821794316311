@import "../variable";
@import "../mixins";

.main-content {
  background: #F5F5F5;
  margin-left: @sidebar-width;
  min-height: 750px;
  .page-content {
    background: #F5F5F5;
    margin-top: 50px;
    overflow: hidden;
    padding: 20px 50px 0;
    position: relative;
    height: 100%;
    h3 {
      font-family: 'Open Sans', sans-serif;
      font-size: 15px;
      margin-bottom: 15px;
      margin-top: 25px;
      text-transform: uppercase;
    }
    &::after {
      clear: both;
      content: '';
      display: block;
    }
    .row-sm[class^="col-"] {
      padding-left: 10px;
      padding-right: 10px;
    }
    .row-xs[class^="col-"] {
      padding-left: 5px;
      padding-right: 5px;
    }
    .colors-list {
      height: 30px;
      list-style: none;
      margin: 0;
      padding: 0;
      > li {
        background: #000000;
        display: inline-block;
        filter: alpha(opacity=60);
        float: left;
        height: 20px;
        margin: 4px;
        opacity: .6;
        padding: 0;
        width: 20px;
        .transition(all, .1s, ease-out);
        .border-radius(50%);
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          cursor: pointer;
          filter: alpha(opacity=100);
          opacity: 1;
        }
        &.active {
          filter: alpha(opacity=100);
          height: 26px;
          margin-top: 0;
          opacity: 1;
          width: 26px;
        }
        &.white {
          background: @main-bg-color;
          border: 1px solid #BFBFBF;
        }
        &.primary {
          background: #319db5;
        }
        &.dark {
          background: #2B2E33;
        }
        &.red {
          background: #C9625F;
        }
        &.green {
          background: #18a689;
        }
        &.blue {
          background: #319DB5;
        }
        &.aero {
          background: #B9D5DB;
        }
        &.gray {
          background: #82848A;
        }
        &.gray-light {
          background: #F4F4F4;
        }
        &.orange {
          background: #F2A057;
        }
        &.yellow {
          background: #ffcc00;
        }
        &.pink {
          background: #DC88E6;
        }
        &.purple {
          background: #A58BD3;
        }
      }
    }
    .colors-list.color-full {
      > li {
        border: 1px solid #5A5A5A;
      }
    }
    .panel {
      background: @main-bg-color;
      border-radius: 0;
      border: 1px solid #E2E2E2;
      border: none;
      color: @main-color-dark;
      margin-bottom: 20px;
      min-height: 30px;
      position: relative;
      .box-shadow(0, 2px, 4px, 0.08);
      &:before {
        content: " ";
        display: table;
      }
      &:after {
        content: " ";
        display: table;
      }
      .panel-content {
        h1 {
          font-family: 'Lato';
          font-weight: 100;
          font-size: 28px;
          margin-top: 0;
        }
        ul {
          padding-left: 0;
        }
        ol {
          padding-left: 0;
        }
        i {
          padding-left: 0;
          padding-right: 6px;
        }
        .btn {
          i {
            padding-right: 0;
          }
        }
      }
      .panel-header[class*="bg-"] {
        h2 {
          color: #fff
        }

      }
      .panel-header {
        display: block;
        height: auto;
        padding: 6px 15px 6px 18px;
        h2 {
          color: @main-color-dark;
          display: block;
          font-family: 'Lato';
          font-size: 24px;
          font-weight: 100;
          line-height: normal;
          margin-bottom: 5px;
          margin-top: 7px;
          text-transform: uppercase;
        }
        h3 {
          display: block;
          font-family: 'Lato', 'Open Sans', sans-serif;
          font-size: 15px;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 10px;
          margin-top: 10px;
          text-transform: uppercase;
        }
        &:hover {
          .control-btn > a {
            background: none !important;
            color: #909090 !important;
            .transition(all, .4s, ease);
          }
        }
        .control-btn > a {
          .icon-size-fullscreen {
            &:before {
              display: inline-block;
              width: 14px;
            }
          }
        }
        .control-btn {
          .btn-group > a {
            color: @main-color-light !important;
            cursor: pointer;
            margin-left: 11px;
            margin-right: 0px;
            .transition(all, .4s, ease);
          }
        }
      }
      .control-btn {
        display: inline-block !important;
        position: absolute;
        right: 13px;
        top: 14px;
        z-index: 2;
        .dropdown-menu {
          z-index: 2;
        }
      }
      .left-toolbar {
        left: 13px;
        position: absolute;
        top: 10px;
        z-index: 2;
      }
      &:hover {
        .control-btn > a.hidden {
          display: inline-block !important;
          visibility: visible !important;
        }
        .control-btn > a.hidden.nevershow {
          display: none !important;
        }
      }
      .control-btn > a.nevershow {
        display: none !important;
      }
      table {
        .btn-group {
          z-index: 1;
        }
      }
      .panel-close {
        font-size: 15px;
        &:hover {
          color: #EB5055 !important;
        }
      }
      .control-btn > a {
        color: @main-color-light !important;
        cursor: pointer;
        margin-left: 11px;
        margin-right: 0px;
        .transition(all, .4s, ease);
        &:hover {
          text-decoration: none;
          text-decoration: none !important;
          .transition(all, .4s, ease);
        }
        &:focus {
          text-decoration: none !important;
        }
      }
      .panel-header.header-line {
        &:before {
          background: rgba(0, 0, 0, 0.1);
          bottom: 0;
          content: "";
          height: 1px;
          left: 16px;
          position: absolute;
          right: 16px;
        }
      }
      .panel-title {
        display: inline-block;
        font-family: 'Lato', 'Open Sans', Helvetica, sans-serif;
        font-size: 24px;
        font-weight: 100;
        margin: 5px;
        padding: 0;
      }
      .panel-footer.footer-line {
        background-color: @main-bg-color;
        &:before {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          content: "";
          height: 1px;
          left: 0;
          position: relative;
          right: 0;
          top: -10px;
          width: 100%;
        }
      }
      .panel-footer {
        background-color: #F4F4F4;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 1px solid #E2E2E2;
        border-top: 1px solid #E2E2E2;
        border-top: none;
        padding: 10px 15px;
        .btn {
          margin-bottom: 0;
        }
      }
      .stat-num {
        font-size: 36px;
        font-weight: bold;
      }
    }
    .panel.panel-transparent {
      -webkit-box-shadow: none;
      background: rgba(0, 0, 0, 0);
      border: none;
      box-shadow: none;
      .panel-content {
        padding: 0;
      }
    }
    .portlets.ui-sortable {
      z-index: 2;
      .panel-header {
        cursor: move;
      }
    }
    .panel.bg-aero {
      .panel-content {
        color: @main-color-dark !important;
      }
    }
    .panel[class*='bg-'].control-btn > a {
      color: @main-color-white !important;
      opacity: 0.8;
    }
    .panel[class*='bg-'].panel-header {
      &:hover {
        .control-btn > a {
          opacity: 1;
        }
      }
    }
    .panel-content {
      padding: 20px 20px 10px 20px;
      .border-radiuses(0, 0, 2px, 2px);
      h3 {
        font-family: 'Open Sans', sans-serif;
        font-size: 15px;
        margin-bottom: 15px;
        margin-top: 25px;
        text-transform: uppercase;
      }
    }
    .panel-transparent {
      .panel-header {
        border: none !important;
      }
      .panel-content {
        border: none !important;
      }
      .panel-footer {
        border: none !important;
      }
    }
    .panel-placeholder {
      background-color: #DFE1E4 !important;
      border: 1px dashed #b6bcbf;
      margin-bottom: 20px;
      visibility: visible !important;
    }
    .panel.maximized {
      -webkit-border-radius: 0;
      border-radius: 0;
      border-top: 1px solid #E8E8E8;
      bottom: 0px;
      left: @sidebar-width;
      margin: 0px;
      padding-top: 10px;
      bottom: 0px;
      left: 0;
      position: absolute;
      right: 0px;
      min-height: 300px;
      top: @topbar-height;
      z-index: 11;
      .border-radius(0);
    }
    .panel-default > .panel-heading {
      border-bottom: none;
    }
    .panel-default {
      border-color: #E8E8E8;
    }
    .sortable {
      .panel-header {
        cursor: move;
      }
    }
    .panel-header {
      i {
        padding-right: 6px;
      }
    }
    .panel-stat {
      border: none;
      overflow: hidden;
      position: relative;
      h1 {
        color: #121212;
      }
      .stat-title {
        color: #121212;
        opacity: 0.7;
        text-transform: uppercase;
      }
      .icon {
        bottom: 45px;
        color: rgba(0, 0, 0, 0.1);
        font-size: 100px;
        line-height: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 5px;
        vertical-align: bottom;
        z-index: 1;
      }
      .bg-dark {
        .icon {
          color: rgba(255, 255, 255, 0.1);
        }
      }
      .big-icon {
        i {
          display: inline-block;
          font-size: 53px;
          margin-top: 8px;
          opacity: 0.3;
        }
      }
      .col-xs-6 {
        &:last-child {
          text-align: right;
        }
      }
    }
    .panel-stat > [class*="bg-"] h1 {
      color: @main-color-white;
    }
    .panel-stat > [class*="bg-"] h3 {
      color: @main-color-white;
    }
    .panel-stat[class*="bg-"].stat-title {
      color: @main-color-white;
    }
    .panel-stat-chart {
      margin-bottom: -10px;
      margin-left: -5px;
      margin-right: -5px;
      margin-top: -20px;
      position: relative;
    }
    #visitors-chart {
      bottom: 0;
      left: 0;
      position: absolute;
    }
  }
  .page-content.page-thin {
    padding: 25px 25px 0 25px;
    .row {
      margin-left: -5px;
      margin-right: -5px;
    }
    .row > [class^="col-"] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .page-content.page-thin.page-builder {
    padding-left: 50px;
    padding-right: 50px;
  }
  .page-content {
    .breadcrumb-wrapper {
      position: absolute;
      right: 0;
      top: 23px;
      .label {
        color: #999999;
        display: inline-block;
        font-size: 11px;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
    .breadcrumb {
      background: none;
      display: inline-block;
      padding: 0;
      li {
        font-family: 'Lato', arial, sans-serif;
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
        a {
          color: #319DB5;
        }
      }
      li.active {
        color: #333333;
      }
    }
    .breadcrumb > li + li {
      &:before {
        color: @main-color-light;
        content: "\2022";
        padding: 0 8px 0 5px;
      }
    }
  }
  .page-content > .header {
    padding: 15px 0;
    position: relative;
    h2 {
      color: #1D2939;
      font-family: 'Lato';
      font-size: 28px;
      letter-spacing: -0.5px;
      margin: 0;
      padding-bottom: 7px;
      font-weight: 100;
      span {
        color: #999999;
        font-size: 13px;
        font-style: italic;
        letter-spacing: 0;
        text-transform: none;
        vertical-align: middle;
        &::before {
          color: @main-color-light;
          content: '/';
          margin: 0 10px 0 5px;
        }
      }
    }
    .glyphicon {
      font-size: 24px;
      margin-right: 5px;
      opacity: 0.5;
      padding: 6px 7px;
      .border-radius(50px);
    }
    i {
      font-size: 24px;
      margin-right: 5px;
      opacity: 0.5;
      padding: 6px 7px;
      .border-radius(50px);
    }
    icon-logoutbefore {
      bottom: -4px;
      display: inline-block;
      font-size: 28px;
      position: relative;
    }
  }
  .page-content > .footer {
    bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
    padding-bottom: 15px;
    .copyright {
      border-top: 1px solid rgba(12, 12, 12, 0.07);
      padding: 20px 0;
      z-index: 15;
      p {
        margin-bottom: 0;
      }
      a {
        color: #2C8CA0;
      }
      span {
        font-size: 88%;
        opacity: 0.8;
      }
      span.copyright {
        font-family: 'Open Sans';
      }
    }
  }
  .page-content > .footer.footer-absolute {
    left: 50px;
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    right: 50px;
  }
  .page-content[class*='bg-'].panel-header {
    .control-btn {
      .btn-group > a {
        color: @main-color-white !important;
        opacity: 0.8;
      }
    }
  }
  .page-content[class*='bg-'].panel {
    .panel-header {
      &:hover {
        .control-btn > a {
          opacity: 1;
        }
      }
    }
  }
  .footer {
    margin-left: 50px;
    margin-right: 50px;
    .copyright {
      border-top: 1px solid rgba(12, 12, 12, 0.07);
      padding: 20px 0;
      z-index: 15;
      p {
        margin-bottom: 0;
      }
      a {
        color: #2C8CA0;
      }
      span {
        font-size: 88%;
        opacity: 0.8;
      }
      span.copyright {
        font-family: 'Open Sans';
      }
    }

  }
}

.main-content .page-content[class^='bg-'].panel h1, .main-content .page-content[class*=' bg-'].panel h1, .main-content .page-content[class^='bg-'].panel h2, .main-content .page-content[class*=' bg-'].panel h2, .main-content .page-content[class^='bg-'].panel h3, .main-content .page-content[class*=' bg-'].panel h3, .main-content .page-content .panel[class^='bg-'] h2, .main-content .page-content .panel[class*=' bg-'] h2, .main-content .page-content .panel[class^='bg-'] h3, .main-content .page-content .panel[class*=' bg-'] h3 {
  color: @main-color-white !important;
}

.main-content .page-content .panel.bg-aero h1, .main-content .page-content .panel.bg-aero h2, .main-content .page-content .panel.bg-aero h3, .main-content .page-content .panel .bg-aero h2, .main-content .page-content .panel .bg-aero h3, .main-content .page-content .panel .bg-gray-light h2, .main-content .page-content .panel .bg-gray-light h3, .main-content .page-content .panel .bg-blue-light h2, .main-content .page-content .panel .bg-blue-light h3 {
  color: @main-color-dark !important;
}

.panel {
  &:hover {
    .control-btn > a.nevershow {
      display: none !important;
    }
  }
  .panel-toggle {
    font-size: 15px;
  }
}

.main-content .page-content .panel-header h3, .main-content .page-content .panel-footer h2, .main-content .page-content .panel-footer h3 {
  margin: 0;
}

.panel-stat {
  h3 {
    color: #121212;
  }
}

.sidebar-top {
  .main-content {
    .page-content {
      .panel.maximized {
        left: 0;
        right: 0;
        top: 115px;
      }
    }
  }
}

.sidebar-collapsed {
  .main-content {
    .page-content {
      .panel.maximized {
        left: @sidebar-collapsed-width;
      }
    }
  }
}

body:not(.fixed-topbar) {
  .main-content {
    .page-content {
      margin-top: 0;
    }
  }
}

.sidebar-top {
  .main-content {
    .page-content {
      top: 0;
    }
  }
}

.sidebar-top.fixed-topbar {
  .main-content {
    .page-content {
      top: 66px;
    }
  }
}

.maximized-panel {
  .page-content {
    height: 100%;
    margin-top: 0;
    padding: 0 15px 0 15px;
    .panel {
      &:not(.maximized) {
        display: none;
      }
    }
    .header {
      display: none;
    }
    .footer {
      display: none;
    }
  }
}

@media screen and (max-width: 991px) {
  .main-content {
    .page-content {
      background: #F1F2F3;
      overflow: hidden;
      padding: 20px;
    }
    .page-content > .header {
      h2 {
        font-size: 20px;
        span {
          display: none;
        }
      }
      .breadcrumb-wrapper {
        display: none;
      }
    }
  }
}

@media (max-width: 500px) {
  .main-content .page-content .panel .control-btn {
    display: none !important
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .main-content .page-content .panel-stat h1 {
    font-size: 24px
  }

  .main-content .page-content .panel-stat h3 {
    font-size: 18px
  }
}