/* MANDATORY CSS */
@import "https://fonts.googleapis.com/css?family=Lato:300,400,700,900";
@import "https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700";
//@import "../plugins/bootstrap/css/bootstrap.min.css";
//@import "../plugins/bootstrap/css/bootstrap-rtl.min.css";
@import "_mcustomscrollbar";
@import "_animate";
@import (inline) "icons/font-awesome/font-awesome.css";
@import (inline) "icons/line-icons/line-icons.css";
@import (inline) "icons/line-icons/simple-line-icons.css";
@import "_select2";
/* Select & Radio Inputs */
@import "_slick";
/* Text & Image Slider */
//@import "_icheck"; /* Checbox & Radio Icheck */
@import "jasny-bootstrap"; /* File Upload and Input Masks */

/* OPTIONAL CSS */
/* Depend if you use this functionnalities in your project */
//@import "icons/rounded/style.css"; /* Rounded Icons - See Icons Page - OPTIONAL */
//@import "icons/octicons/octicons.css"; /* Topbar Left Icons - OPTIONAL */
//@import "icons/weather/css/weather-icons.min.css"; /* Weather Icons - See Icons Page - OPTIONAL */
//@import "../plugins/bootstrap-loading/lada.min.css"; /* Buttons Loading State - See Buttons Page - OPTIONAL */
//@import "../plugins/bootstrap-tags-input/bootstrap-tagsinput.css"; /* Tags Input - See Form Elements Page - OPTIONAL */
//@import "../plugins/bootstrap-editable/css/bootstrap-editable.css"; /* Inline Edition X-Editable - OPTIONAL */
//@import "../plugins/prettify/prettify.css"; /* Show Html Code - OPTIONAL */

/* FONTS */
@font-face {
  font-family: 'weather';
  src: url('icons/weather/artill_clean_icons-webfont.eot');
  src: url('icons/weather/artill_clean_icons-webfont.eot?#iefix') format('embedded-opentype'), url('icons/weather/artill_clean_icons-webfont.woff') format('woff'), url('icons/weather/artill_clean_icons-webfont.ttf') format('truetype'), url('icons/weather/weatherfont/artill_clean_icons-webfont.svg#artill_clean_weather_iconsRg') format('svg');
  font-weight: normal;
  font-style: normal
}
