.cursor-pointer {
  cursor: pointer
}

code {
  padding: 2px 4px 2px 4px;
  font-size: 90%;
  color: #575757;
  background-color: #EEF4FF;
  white-space: nowrap;
  border-radius: 4px;
}

.line-separator {
  border-right: 1px solid #DBE2E7;
}

img.img-left {
  border: 1px solid #ccc;
  float: left;
  margin-right: 15px;
  padding: 5px;
}

img.img-right {
  border: 1px solid #ccc;
  float: right;
  margin-left: 15px;
  padding: 5px;
}

.hide {
  opacity: 0;
}

a, a:focus, a:hover, a:active {
  outline: 0;
}

li {
  line-height: 25px;
}

img.img-left {
  border: 1px solid #cccccc;
  float: left;
  margin-right: 15px;
  padding: 5px;
}

img.img-right {
  border: 1px solid #cccccc;
  float: right;
  margin-left: 15px;
  padding: 5px;
}

textarea {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
}

ul, li, input, .input-group, p, label, div, section, textarea {
  float: none;
  text-align: left;
}

.z2 {
  z-index: 2
}

.ov-hidden {
  overflow: hidden
}

.label-dark {
  background-color: rgba(0, 0, 0, 0.6);
  padding: .4em .8em .5em;
}

label.required:after {
  content: '*';
  color: #FF5757;
  margin-left: 2px;
}

.dropdown-menu > li > a.no-option {
  padding: 0;
  height: 0;
}

button .fa-trash, .trash-link {
  font-size: 20px;
  display: inline-block;
  color: #C75757 !important;
}

button .fa-trash:hover, .trash-link:hover {
  color: #A33636;
}

.btn.btn-link {
  padding: 4px !important;
  border: none !important;
  background: rgba(0, 0, 0, 0) none !important;
}

.btn.btn-link i {
  padding-right: 0 !important;
}

.nav-pills > li > a {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.nav-tabs > li > a {
  -moz-border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.dropdown-menu {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 5px;
  -moz-box-shadow: 3px 3px 0 rgba(12, 12, 12, 0.03);
  -webkit-box-shadow: 3px 3px 0 rgba(12, 12, 12, 0.03);
  box-shadow: 3px 3px 0 rgba(12, 12, 12, 0.03)
}

.dropdown-menu li a {
  -moz-transition: all .2s ease-out 0;
  -webkit-transition: all .2s ease-out 0;
  transition: all .2s ease-out 0
}

.dropdown-menu > li > a {
  padding: 7px 10px;
  color: #666
}

.dropdown-menu > li > a:hover {
  background-color: #e7e7e7;
  color: #333
}

.dropdown-menu li a i {
  padding-right: 8px;
  padding-left: 0;
}

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}

input.parsley-success,
select.parsley-success,
textarea.parsley-success {
  color: #468847;
  background-color: #DFF0D8;
  border: 1px solid #D6E9C6;
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
  color: #B94A48;
  background-color: #F2DEDE;
  border: 1px solid #EED3D7;
}

.parsley-errors-list {
  margin: 8px 0 3px 0;
  padding: 0;
  list-style-type: none;
  font-size: 0.9em;
  line-height: 0.9em;
  opacity: 0;
  -moz-opacity: 0;
  -webkit-opacity: 0;
  color: #AA0000;
  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -ms-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
}

.parsley-errors-list.filled {
  opacity: 1;
}

.jstree-default.jstree-rtl .jstree-node {
  background: none !important;
  background-image: none !important;
}

.jstree-default.jstree-rtl .jstree-closed > .jstree-ocl {
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.rtl .jstree-default .jstree-ocl {
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

