/* Mixin */
@base: #663333;
@complement1: spin(@base, 180);
@complement2: darken(spin(@base, 180), 5%);
@lighten1: lighten(@base, 15%);
@lighten2: lighten(@base, 30%);

.opacity (@opacity: 0.5) {
  -webkit-opacity: @opacity;
  -moz-opacity: @opacity;
  opacity: @opacity;
  filter: opacity(100*@opacity);
}

.border-radius (@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

.border-radiuses (@topleft: 5px, @topright: 5px, @bottomright: 5px, @bottomleft: 5px) {
  -webkit-border-radius: @topleft @topright @bottomright @bottomleft;
  -moz-border-radius: @topleft @topright @bottomright @bottomleft;
  border-radius: @topleft @topright @bottomright @bottomleft;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.box-shadow (@x: 0px, @y: 3px, @blur: 5px, @alpha: 0.5) {
  -webkit-box-shadow: @x @y @blur rgba(0, 0, 0, @alpha);
  -moz-box-shadow: @x @y @blur rgba(0, 0, 0, @alpha);
  box-shadow: @x @y @blur rgba(0, 0, 0, @alpha);
}

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

.transition_ (@prop...) {
  -webkit-transition: @prop;
  -moz-transition: @prop;
  -o-transition: @prop;
  -ms-transition: @prop;
  transition: @prop;
}

.transition-duration(@duration: 0.2s) {
  -moz-transition-duration: @duration;
  -webkit-transition-duration: @duration;
  -o-transition-duration: @duration;
  transition-duration: @duration;
}

.transition-delay(@delay: 0.2s) {
  -moz-transition-delay: @delay;
  -webkit-transition-delay: @delay;
  -o-transition-delay: @delay;
  transition-delay: @delay;
}

.transform (@rotate: 90deg, @scale: 1, @skew: 1deg, @translate: 10px) {
  -webkit-transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
  -moz-transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
  -o-transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
  -ms-transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
  transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
}

.rotation(@deg:5deg) {
  -moz-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  -o-transform: rotate(@deg);
  -webkit-transform: rotate(@deg);
  transform: rotate(@deg);
}

.scale(@ratio:1.5) {
  -moz-transform: scale(@ratio);
  -o-transform: scale(@ratio);
  -webkit-transform: scale(@ratio);
  transform: scale(@ratio);
}

.translate(@x:0, @y:0) {
  .transform(translate(@x, @y));
}

.opacity(@opacity: 0.5) {
  -moz-opacity: @opacity;
  -khtml-opacity: @opacity;
  -webkit-opacity: @opacity;
  opacity: @opacity;
  @opperc: @opacity * 100;
}
