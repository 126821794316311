//
//  =========================================================
//  Advies4me
//  Sass Stylesheet
//  =========================================================
//

hr > a {
  color: #337ab7
}

hr > a:hover {
  color: #337ab7
}

.version {
  color: #c7c0c0;
  position: absolute;
  bottom: 50px;
  font-size: 10px;
  right: 25px;
}

//
//  =========================================================
//  Base Variables
//  =========================================================
//

.dataTables_processing {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  padding-top: 5%;
  background: rgba(256, 256, 256, .5);
}

//
//  =========================================================
//  Imports
//  =========================================================
//

@import "bootstrap";

@import "style";

/* <import> */

#required-fields {
  padding-bottom: 20px;
}

table.no-border td {
  border: none !important;
}

table.no-padding td {
  padding: 0 !important;
}

table.no-margin td {
  margin: 0 !important;
}

table.excel-table {
  margin-bottom: 0 !important;
}

table.excel-table td {
  border: 1px solid #cccccc;
  padding: 0 !important;
}

table.excel-table thead td {
  background-color: #dedede;
}

table.excel-table thead td select {
  width: 100%;
}

table.excel-table tbody td {
  padding-left: 2px;
  white-space: nowrap;
  overflow: hidden;
  min-width: 50px;
}

table.excel-table td:first-child {
  width: 40px;
  background-color: #dedede;
  text-align: center;
  padding-left: 0 !important;
}

table.excel-table td:not(:first-child) {
  padding-left: 5px !important;
}

table.excel-table tfoot td {
  border-top: 3px solid #cccccc;
}

div.width-90 {
  width: 90%;
}

div.table-container {
  position: relative;
  overflow-x: scroll;
}

div.sluier {
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1)));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
}

/* </import> */

/* <dynatable> */
.dynatable-sort-header {
  color: #2c8ca1;
}

.dynatable-sort-header:hover {
  color: #80BAC7;
}

/* </dynatable> */

/* <campaign overview> */
.campaign-item {
  cursor: pointer;
}

/* </campainoverview> */

/* Small style fixes */
.selectWidth {
  width: auto;
  margin: 10px;
}

/* <custom datatables> */

.dataTables_length, .dataTables_info {
  width: auto;
  float: left;
}

.dataTables_paginate {
  float: right;
  font-size: 1.3em;
}

.paginate_button {
  padding: 5px;
  cursor: pointer;
}

.paginate_button .current {
  color: #337ab7;
}

/* The ribbons */
.corner-ribbon {
  width: 200px;
  background: #e43;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  z-index: 999;
}

/* Custom styles */

.corner-ribbon.sticky {
  position: fixed;
}

.corner-ribbon.shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, .3);
}

/* Different positions */

.corner-ribbon.top-left {
  top: 25px;
  left: -50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.top-right {
  top: 25px;
  right: -50px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-left {
  top: auto;
  bottom: 25px;
  left: -50px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-right {
  top: auto;
  right: -50px;
  bottom: 25px;
  left: auto;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Colors */

.corner-ribbon.white {
  background: #f0f0f0;
  color: #555;
}

.corner-ribbon.black {
  background: #333;
}

.corner-ribbon.grey {
  background: #999;
}

.corner-ribbon.blue {
  background: #39d;
}

.corner-ribbon.green {
  background: #2c7;
}

.corner-ribbon.turquoise {
  background: #1b9;
}

.corner-ribbon.purple {
  background: #95b;
}

.corner-ribbon.red {
  background: #e43;
}

.corner-ribbon.orange {
  background: #e82;
}

.corner-ribbon.yellow {
  background: #ec0;
}

div.clickable-panel {
  cursor: pointer;
}

div.clickable-panel:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4) !important;
}

.variable {
  cursor: default;
  background-color: #319db5;
  color: #FFF;
  padding: 2px 8px;
  border-radius: 3px;
  font-weight: bold;
  font-style: normal;
  font-size: 12px;
  display: inline-block;
  line-height: 12px;
}

div.bgl-gray {
  background-color: #E4E4E4 !important;
  color: #2C2C2C !important;
}

div.catalogue-item-body {
  overflow: hidden;
  height: 145px;
}

#trackingcode-submit-button {
  z-index: 10;
}

#survey .btn-group {
  margin-right: 0;
}

/* fix voor foutje in style */
#survey .btn-group > .btn:last-child :not(:first-child) {
  border-left: 0 !important;
}

#survey .btn-group > .btn:last-child:not(:first-child) {
  border-left: 1px solid rgba(12, 12, 12, 0.1) !important;
}

.label-blue {
  background-color: #4584D1;
}

.label-green {
  background-color: #18A689;
}

.label-yellow {
  background-color: #EBC85E;
}

.label-red {
  background-color: #C9625F;
}

.label-orange {
  background-color: #F2A057;
}

.label-purple {
  background-color: #A58BD3;
}

.label-gray {
  background-color: #82848A;
}

.badge-blue {
  background-color: #4584D1;
}

.badge-green {
  background-color: #18A689;
}

.badge-yellow {
  background-color: #EBC85E;
}

.badge-red {
  background-color: #C9625F;
}

.badge-orange {
  background-color: #F2A057;
}

.badge-purple {
  background-color: #A58BD3;
}

.badge-gray {
  background-color: #82848A;
}

.tooltip.in {
  filter: alpha(opacity=90);
  -webkit-filter: opacity(1);
  filter: opacity(90);
  -moz-opacity: 0.9;
  -khtml-opacity: 0.9;
  -webkit-opacity: 0.9;
  opacity: 0.9;
}

.table-no-border td,
.table-no-border th {
  border: none !important;
  margin-bottom: 20px !important;;
}

.table-condensed thead > tr > th,
.table-condensed tbody > tr > th,
.table-condensed tfoot > tr > th,
.table-condensed thead > tr > td,
.table-condensed tbody > tr > td,
.table-condensed tfoot > tr > td {
  padding: 1px;
  vertical-align: middle;
}

.table-condensed thead > tr > td,
.table-condensed tbody > tr > td,
.table-condensed tfoot > tr > td {
  border: 0px;
}

.table-condensed thead > tr:first-child td,
.table-condensed tbody > tr:first-child td,
.table-condensed tfoot > tr:first-child td {
  padding-top: 3px;
}

.table-no-padding > thead > tr > th,
.table-no-padding > tbody > tr > th,
.table-no-padding > tfoot > tr > th,
.table-no-padding > thead > tr > td,
.table-no-padding > tbody > tr > td,
.table-no-padding > tfoot > tr > td {
  padding-left: 0px !important;
  padding-bottom: 4px !important;
  vertical-align: middle;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  /* background-color: #f3f3f3; */
  background-color: #fff;
}

.table-fixed {
  table-layout: fixed;
  overflow-wrap: break-word;
}

table.dataTable {
  clear: both;
}

#campaign-create-page .customer-pay,
#campaign-create-page .payment-info,
#campaign-create-page .tax-info {
  display: none;
}

#campaign-create-page .customer-pay.active,
#campaign-create-page .payment-info.active,
#campaign-create-page .tax-info.active {
  display: block;
}