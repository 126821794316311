@import "../variable";
@import "../mixins";

.sp-container {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: inline;
  display: inline-block;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 9999994;
  zoom: 1;
  * {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
}

.sp-container.sp-flat {
  position: relative;
}

.sp-top {
  display: inline-block;
  position: relative;
  width: 100%;
}

.sp-top-inner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.sp-color {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 20%;
  top: 0;
}

.sp-hue {
  bottom: 0;
  height: 100%;
  left: 84%;
  position: absolute;
  right: 0;
  top: 0;
}

.sp-clear-enabled {
  .sp-hue {
    height: 77.5%;
    top: 33px;
  }
  .sp-clear {
    bottom: 0;
    display: block;
    height: 28px;
    left: 84%;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.sp-fill {
  padding-top: 80%;
}

.sp-sat {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.sp-val {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.sp-alpha-enabled {
  .sp-top {
    margin-bottom: 18px;
  }
  .sp-alpha {
    display: block;
  }
}

.sp-alpha-handle {
  background: white;
  border: 1px solid black;
  bottom: -4px;
  cursor: pointer;
  left: 50%;
  opacity: .8;
  position: absolute;
  top: -4px;
  width: 6px;
}

.sp-alpha {
  bottom: -14px;
  display: none;
  height: 8px;
  left: 0;
  position: absolute;
  right: 0;
}

.sp-alpha-inner {
  border: solid 1px #333333;
}

.sp-clear {
  display: none;
}

.sp-clear.sp-clear-display {
  background-position: center;
}

.sp-container, .sp-replacer, .sp-preview, .sp-dragger, .sp-slider, .sp-alpha, .sp-clear, .sp-alpha-handle, .sp-container.sp-dragging .sp-input, .sp-container button {
  -moz-user-select: -moz-none;
  -o-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.sp-container.sp-input-disabled {
  .sp-input-container {
    display: none;
  }
}

.sp-container.sp-buttons-disabled {
  .sp-button-container {
    display: none;
  }
}

.sp-palette-only {
  .sp-picker-container {
    display: none;
  }
}

.sp-palette-disabled {
  .sp-palette-container {
    display: none;
  }
}

.sp-initial-disabled {
  .sp-initial {
    display: none;
  }
}

.sp-sat {
  background-image: -webkit-gradient(linear, 0 0, 100% 0, from(#FFF), to(rgba(204, 154, 129, 0)));
  background-image: -webkit-linear-gradient(left, #FFF, rgba(204, 154, 129, 0));
  background-image: -moz-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
  background-image: -o-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
  background-image: -ms-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
  background-image: linear-gradient(to right, #fff, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType = 1, startColorstr=#FFFFFFFF, endColorstr=#00CC9A81)";
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr='#FFFFFFFF', endColorstr='#00CC9A81')
}

.sp-val {
  background-image: -webkit-gradient(linear, 0 100%, 0 0, from(#000), to(rgba(204, 154, 129, 0)));
  background-image: -webkit-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
  background-image: -moz-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
  background-image: -o-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
  background-image: -ms-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
  background-image: linear-gradient(to top, #000, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00CC9A81, endColorstr=#FF000000)";
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00CC9A81', endColorstr='#FF000000')
}

.sp-hue {
  background: -moz-linear-gradient(top, #f00 0, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
  background: -ms-linear-gradient(top, #f00 0, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
  background: -o-linear-gradient(top, #f00 0, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#f00), color-stop(0.17, #ff0), color-stop(0.33, #0f0), color-stop(0.5, #0ff), color-stop(0.67, #00f), color-stop(0.83, #f0f), to(#f00));
  background: -webkit-linear-gradient(top, #f00 0, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%)
}

.sp-1 {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@color0', endColorstr='#ffff00');
  height: 17%;
}

.sp-2 {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff00', endColorstr='#00ff00');
  height: 16%;
}

.sp-3 {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff00', endColorstr='#00ffff');
  height: 17%;
}

.sp-4 {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffff', endColorstr='#0000ff');
  height: 17%;
}

.sp-5 {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0000ff', endColorstr='#ff00ff');
  height: 16%;
}

.sp-6 {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00ff', endColorstr='@color0');
  height: 17%;
}

.sp-hidden {
  display: none !important;
}

.sp-cf {
  zoom: 1;
  &::before {
    content: "";
    display: table;
  }
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

.sp-dragger {
  background: #000000;
  border-radius: 5px;
  border: 1px solid #ffffff;
  cursor: pointer;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 5px;
}

.sp-slider {
  background: #31333C;
  border: 1px solid #31333C;
  cursor: pointer;
  height: 3px;
  left: -1px;
  opacity: .8;
  position: absolute;
  right: -1px;
  top: 0;
}

.sp-container {
  background-color: #ffffff;
  border-radius: 0;
  border: solid 1px #D7D7D7;
  padding: 0;
  input {
    background: #ffffff;
    border: 0;
  }
}

.sp-container, .sp-container button, .sp-container input, .sp-color, .sp-hue, .sp-clear {
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.sp-top {
  margin-bottom: 3px;
}

.sp-color {
  border: none;
}

.sp-hue {
  border: none;
}

.sp-clear {
  border: none;
}

.sp-input-container {
  float: right;
  margin-bottom: 4px;
  width: 100px;
}

.sp-initial-disabled {
  .sp-input-container {
    width: 100%;
  }
}

.sp-input {
  background: transparent;
  border-radius: 3px;
  border: 1px inset;
  color: #222222;
  font-size: 12px !important;
  margin: 0;
  padding: 4px 5px;
  width: 100%;
  &:focus {
    border: 1px solid orange;
  }
}

.sp-input.sp-validation-error {
  background: #ffdddd;
  border: 1px solid red;
}

.sp-picker-container {
  border-left: solid 1px #ffffff;
  float: left;
  padding: 10px;
  margin-bottom: -290px;
  padding-bottom: 300px;
  position: relative;
}

.sp-palette-container {
  border-right: solid 1px #cccccc;
  float: left;
  margin-bottom: -290px;
  padding: 10px;
  padding-bottom: 300px;
  position: relative;
}

.sp-palette {
  .sp-thumb-el {
    border: solid 2px transparent;
    cursor: pointer;
    display: block;
    float: left;
    height: 15px;
    margin: 3px;
    position: relative;
    width: 24px;
    &:hover {
      border-color: orange;
    }
  }
  .sp-thumb-el.sp-thumb-active {
    border-color: orange;
  }
}

.sp-thumb-el {
  position: relative;
}

.sp-initial {
  border: solid 1px #333333;
  float: left;
  span {
    border: 0;
    display: block;
    float: left;
    height: 25px;
    margin: 0;
    width: 30px;
  }
  .sp-clear-display {
    background-position: center;
  }
}

.sp-button-container {
  float: right;
}

.sp-replacer {
  background: #C9C9C9;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  height: 35px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  vertical-align: middle;
  width: 55px;
  &:hover {
    border-color: #f0c49b;
    color: #111111;
  }
}

.sp-replacer.sp-active {
  border-color: #f0c49b;
  color: #111111;
}

.sp-replacer.sp-disabled {
  border-color: silver;
  color: silver;
  cursor: default;
}

.sp-dd {
  display: none;
  float: left;
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  padding: 2px 0;
}

.sp-preview {
  background-image: url(data:image/png; base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
  float: left;
  height: 100%;
  margin-right: 5px;
  position: relative;
  position: relative;
  width: 100%;
  z-index: 0;
}

.sp-palette {
  max-width: 220px;
  width: 220px;
  .sp-thumb-el {
    border: solid 1px #d0d0d0;
    height: 16px;
    margin: 2px 1px;
    width: 16px;
  }
  span {
    &:hover {
      border-color: #000000;
    }
  }
  span.sp-thumb-active {
    border-color: #000000;
  }
  .sp-thumb-inner {
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  .sp-thumb-light.sp-thumb-active {
    .sp-thumb-inner {
      background-image: url(data:image/png; base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIVJREFUeNpiYBhsgJFMffxAXABlN5JruT4Q3wfi/0DsT64h8UD8HmpIPCWG/KemIfOJCUB+Aoacx6EGBZyHBqI+WsDCwuQ9mhxeg2A210Ntfo8klk9sOMijaURm7yc1UP2RNCMbKE9ODK1HM6iegYLkfx8pligC9lCD7KmRof0ZhjQACDAAceovrtpVBRkAAAAASUVORK5CYII=);
    }
  }
  .sp-thumb-dark.sp-thumb-active {
    .sp-thumb-inner {
      background-image: url(data:image/png; base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAAMdJREFUOE+tkgsNwzAMRMugEAahEAahEAZhEAqlEAZhEAohEAYh81X2dIm8fKpEspLGvudPOsUYpxE2BIJCroJmEW9qJ+MKaBFhEMNabSy9oIcIPwrB+afvAUFoK4H0tMaQ3XtlrggDhOVVMuT4E5MMG0FBbCEYzjYT7OxLEvIHQLY2zWwQ3D+9luyOQTfKDiFD3iUIfPk8VqrKjgAiSfGFPecrg6HN6m/iBcwiDAo7WiBeawa+Kwh7tZoSCGLMqwlSAzVDhoK+6vH4G0P5wdkAAAAASUVORK5CYII=);
    }
  }
}

.sp-container {
  padding-bottom: 0;
}

.sp-cancel {
  color: #ffffff !important;
  font-size: 11px;
  margin-bottom: 0 !important;
  margin-right: 5px;
  margin: 0;
  padding: 2px;
  text-decoration: none;
  vertical-align: middle;
}

.sp-alpha {
  background-image: url(data:image/png; base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
  position: relative;
}

.sp-thumb-el {
  background-image: url(data:image/png; base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
  position: relative;
}

.sp-preview-inner {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.sp-alpha-inner {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.sp-thumb-inner {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.sp-clear-display {
  background-image: url(data:image/gif; background-position:center; background-repeat:no-repeat; base64,R0lGODlhFAAUAPcAAAAAAJmZmZ2dnZ6enqKioqOjo6SkpKWlpaampqenp6ioqKmpqaqqqqurq/Hx8fLy8vT09PX19ff39/j4+Pn5+fr6+vv7+wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAAUABQAAAihAP9FoPCvoMGDBy08+EdhQAIJCCMybCDAAYUEARBAlFiQQoMABQhKUJBxY0SPICEYHBnggEmDKAuoPMjS5cGYMxHW3IiT478JJA8M/CjTZ0GgLRekNGpwAsYABHIypcAgQMsITDtWJYBR6NSqMico9cqR6tKfY7GeBCuVwlipDNmefAtTrkSzB1RaIAoXodsABiZAEFB06gIBWC1mLVgBa0AAOw==);
}

.builder {
  .sp-picker-container {
    .btn {
      display: inline-block;
      float: left;
      width: auto;
    }
  }
}

@media (max-device-width: 480px) {
  .sp-color {
    right: 40%;
  }

  .sp-hue {
    left: 63%;
  }

  .sp-fill {
    padding-top: 60%;
  }
}