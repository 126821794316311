@import "../variable";
@import "../mixins";

/**** Font Property ****/
.line-through {
  text-decoration: line-through;
}

.t-ellipsis {
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}

/****  Font Size  ****/
.bigger-110 {
  font-size: 110% !important;
}

.bigger-120 {
  font-size: 120% !important;
}

.f-5 {
  font-size: 5px !important;
}

.f-6 {
  font-size: 6px !important;
}

.f-7 {
  font-size: 7px !important;
}

.f-8 {
  font-size: 8px !important;
}

.f-9 {
  font-size: 9px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-19 {
  font-size: 19px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-24 {
  font-size: 24px !important;
}

.f-26 {
  font-size: 26px !important;
}

.f-28 {
  font-size: 28px !important;
}

.f-30 {
  font-size: 30px !important;
}

.f-32 {
  font-size: 32px !important;
}

.f-34 {
  font-size: 34px !important;
}

.f-36 {
  font-size: 36px !important;
}

.f-38 {
  font-size: 38px !important;
}

.f-40 {
  font-size: 40px !important;
}

.f-60 {
  font-size: 60px !important;
}

.f-65 {
  font-size: 65px !important;
}

.f-80 {
  font-size: 80px !important;
}

.f-150 {
  font-size: 150px !important;
}

.text-sm {
  font-size: 0.8em
}

.text-2x {
  font-size: 2em
}

.text-3x {
  font-size: 3em
}

.text-4x {
  font-size: 4em
}

/**** Text Position ****/
.t-center {
  text-align: center !important;
}

.t-left {
  text-align: left !important;
}

.t-right {
  text-align: right !important;
}

.l-through {
  text-decoration: line-through;
}

/****  Font Weight  ****/
.bold {
  font-weight: 600 !important;
}

.w-300 {
  font-weight: 300 !important;
}

.w-500 {
  font-weight: 500 !important;
}

.w-600 {
  font-weight: 600 !important;
}

.w-700 {
  font-weight: 700 !important;
}

/****  Font Color  ****/
.c-red {
  color: #cd6a6a;
}

.c-blue {
  color: #00A2D9;
}

.c-purple {
  color: #B57EE0;
}

.c-brown {
  color: #9E7B2E;
}

.c-orange {
  color: #ec8521;
}

.c-yellow {
  color: #F3D261;
}

.c-green {
  color: #18A689;
}

.c-gray-light {
  color: #dadada
}

.c-gray {
  color: #8F8F8F;
}

.c-dark {
  color: #343434;
}

.c-white {
  color: #fff;
}

.c-light {
  color: #B3B3B3;
}

.c-primary {
  color: #319DB5
}

.transparent-color {
  color: rgba(0, 0, 0, 0.2);
}

.asterisk {
  color: #D9534F
}

.help-block {
  color: #AFAAAA;
}

.text-primary {
  color: #319DB5
}
