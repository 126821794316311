@global-ui-color-light: #efefef;

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-12 {
  margin-top: 12px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-60 {
  margin-top: 60px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-6 {
  margin-bottom: 6px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-12 {
  margin-bottom: 12px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-60 {
  margin-bottom: 60px !important;
}

.m-b-80 {
  margin-bottom: 80px !important;
}

.m-b-100 {
  margin-bottom: 100px !important;
}

.m-b-140 {
  margin-bottom: 140px !important;
}

.m-b-245 {
  margin-bottom: 245px !important;
}

.m-b-m30 {
  margin-bottom: -30px !important;
}

.m-b-m50 {
  margin-bottom: -50px !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-60 {
  margin-left: 60px !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-r-60 {
  margin-right: 60px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-60 {
  padding-top: 60px !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.t-0 {
  top: 0;
}

.t-5 {
  top: 5px;
}

.t-10 {
  top: 10px;
}

.t-15 {
  top: 15px;
}

.b-0 {
  bottom: 0;
}

.b-5 {
  bottom: 5px;
}

.b-10 {
  bottom: 10px;
}

.b-15 {
  bottom: 15px;
}

.l-0 {
  left: 0;
}

.l-5 {
  left: 5px;
}

.l-10 {
  left: 10px;
}

.l-15 {
  left: 15px;
}

.r-0 {
  right: 0;
}

.r-5 {
  right: 5px;
}

.r-10 {
  right: 10px;
}

.r-15 {
  right: 15px;
}

.bd-0 {
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  border-radius: 0 !important;
}

.bd-3 {
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  border-radius: 3px !important;
}

.bd-6 {
  -moz-border-radius: 6px !important;
  -webkit-border-radius: 6px !important;
  border-radius: 6px !important;
}

.bd-9 {
  -moz-border-radius: 9px !important;
  -webkit-border-radius: 9px !important;
  border-radius: 9px !important;
}

.bd-full {
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
}

.bd-50p {
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
}

.opacity-0 {
  opacity: 0;
  p {
    opacity: 0;
  }
}

.opacity-20 {
  opacity: .2;
  p {
    opacity: .2;
  }
}

.opacity-50 {
  opacity: .5;
  p {
    opacity: .5;
  }
}

.opacity-70 {
  opacity: .7;
  p {
    opacity: .7;
  }
}

.opacity-90 {
  opacity: .9;
  p {
    opacity: .9;
  }
}

.width-0 {
  width: 0 !important;
}

.width-40 {
  width: 40px !important;
}

.width-300 {
  width: 300px !important;
}

.min-width-40 {
  min-width: 40px;
}

.width-16p {
  width: 16.6666% !important;
}

.width-20p {
  width: 20% !important;
}

.width-100p {
  width: 100% !important;
}

.h-0 {
  height: 0 !important;
  overflow: hidden !important;
}

.h-20 {
  height: 20px !important;
  overflow: hidden !important;
}

.h-30 {
  height: 30px !important;
  overflow: hidden !important;
}

.h-40 {
  height: 40px !important;
  overflow: hidden !important;
}

.h-50 {
  height: 50px !important;
  overflow: hidden !important;
}

.h-70 {
  height: 70px !important;
  overflow: hidden !important;
}

.h-90 {
  height: 90px !important;
  overflow: hidden !important;
}

.h-100 {
  height: 100px !important;
  overflow: hidden !important;
}

.h-150 {
  height: 150px !important;
  overflow: hidden !important;
}

.h-220 {
  height: 220px !important;
  overflow: hidden !important;
}

.h-250 {
  height: 250px !important;
  overflow: hidden !important;
}

.h-280 {
  height: 280px !important;
  overflow: hidden !important;
}

.h-300 {
  height: 300px !important;
  overflow: hidden !important;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.dis-inline {
  display: inline;
}

.dis-inline-b {
  display: inline-block;
}

.dis-block {
  display: block !important;
}

.dis-none {
  display: none;
}

.f-left {
  float: left !important;
}

.f-right {
  float: right !important;
}

.no-border {
  border: none !important;
  box-shadow: none;
}

.no-bd {
  border: none !important;
  box-shadow: none;
}

.border {
  border: 1px solid @global-ui-color-light !important;
}

.border-primary {
  border-color: #319DB5;
}

.border-gray {
  border: 1px solid @global-ui-color-light;
}

.border-bottom {
  border-bottom: 1px solid @global-ui-color-light !important;
}

.border-top {
  border-top: 1px solid @global-ui-color-light !important;
}

.border-right {
  border-right: 1px solid @global-ui-color-light !important;
}

.border-left {
  border-left: 1px solid @global-ui-color-light !important;
}

.border-hor {
  border-bottom: 1px solid @global-ui-color-light !important;
  border-top: 1px solid @global-ui-color-light !important;
}

.border-ver {
  border-left: 1px solid @global-ui-color-light !important;
  border-right: 1px solid @global-ui-color-light !important;
}

.bd-white {
  border-color: #ffffff !important;
}

.bd-green {
  border-left: 3px solid #18a689;
}

.bd-red {
  border-left: 3px solid #c9625f;
}

.bd-blue {
  border-left: 3px solid #319DB5;
}

.bd-t-red {
  border-top: 4px solid #c9625f;
}

.bd-t-green {
  border-top: 4px solid #5FB675;
}

.bd-t-blue {
  border-top: 4px solid #0090D9;
}

.bd-t-dark {
  border-top: 4px solid #2B2E33;
}

.bd-t-purple {
  border-top: 4px solid #A58BD3;
}

.bd-l-red {
  border-left: 4px solid #c9625f;
}

.bd-l-green {
  border-left: 4px solid #18a689;
}

.bd-l-blue {
  border-left: 4px solid #1D86A9;
}

.bd-l-dark {
  border-left: 4px solid #2B2E33;
}

.bd-l-purple {
  border-left: 4px solid #A58BD3;
}

.bd-b-red {
  border-bottom: 4px solid #c9625f;
}

.bd-b-green {
  border-bottom: 4px solid #18a689;
}

.bd-b-blue {
  border-bottom: 4px solid #319DB5;
}

.bd-b-dark {
  border-bottom: 4px solid #2B2E33;
}

.bd-b-purple {
  border-bottom: 4px solid #A58BD3;
}

.img-sm {
  height: 35px;
  width: 35px;
}

.img-md {
  height: 65px;
  width: 65px;
}

.img-lg {
  height: 120px;
  width: 120px;
}

.bg-gray {
  &:not(i) {
    background-color: #82848A !important;
    color: #000000 !important;
  }
}

i.bg-gray {
  &::before {
    background-color: #82848A !important;
    color: #000000 !important;
  }
}

.bg-gray.form-control {
  &:focus {
    background-color: #82848A !important;
    color: #000000 !important;
  }
}

.bg-gray-light {
  &:not(i) {
    background-color: #fafafa !important;
    color: #5B5B5B !important;
  }
}

i.bg-gray-light {
  &::before {
    background-color: #fafafa !important;
    color: #5B5B5B !important;
  }
}

.bg-gray-light.form-control {
  &:focus {
    background-color: #fafafa !important;
    color: #5B5B5B !important;
  }
}

.modal-footer.bg-gray-light {
  &:not(i) {
    background-color: #EFEFEF !important;
    color: #5B5B5B !important;
  }
}

.bg-red {
  &:not(i) {
    background-color: #C9625F !important;
    color: #fff !important;
  }
}

i.bg-red {
  &::before {
    background-color: #C9625F !important;
    color: #fff !important;
  }
}

.bg-red.form-control {
  &:focus {
    background-color: #C9625F !important;
    color: #fff !important;
  }
}

.bg-yellow {
  &:not(i) {
    background-color: #EBC85E !important;
    color: #fff !important;
  }
}

i.bg-yellow {
  &::before {
    background-color: #EBC85E !important;
    color: #fff !important;
  }
}

.bg-yellow.form-control {
  &:focus {
    background-color: #EBC85E !important;
    color: #fff !important;
  }
}

.bg-white {
  &:not(i) {
    background-color: #fff !important;
    color: black !important;
  }
}

i.bg-white {
  &::before {
    background-color: #fff !important;
    color: black !important;
  }
}

.bg-white.form-control {
  &:focus {
    background-color: #fff !important;
    color: black !important;
  }
}

.bg-light {
  &:not(i) {
    background-color: #E9E9E9 !important;
    color: black !important;
  }
}

i.bg-light {
  &::before {
    background-color: #E9E9E9 !important;
    color: black !important;
  }
}

.bg-light.form-control {
  &:focus {
    background-color: #E9E9E9 !important;
    color: black !important;
  }
}

.bg-green {
  &:not(i) {
    background-color: #18a689 !important;
    color: #fff !important;
  }
}

i.bg-green {
  &::before {
    background-color: #18a689 !important;
    color: #fff !important;
  }
}

.bg-green.form-control {
  &:focus {
    background-color: #18a689 !important;
    color: #fff !important;
  }
}

.bg-blue {
  &:not(i) {
    background-color: #4584D1 !important;
    color: #fff !important;
  }
}

i.bg-blue {
  &::before {
    background-color: #4584D1 !important;
    color: #fff !important;
  }
}

.bg-blue.form-control {
  &:focus {
    background-color: #4584D1 !important;
    color: #fff !important;
  }
}

.bg-blue2 {
  &:not(i) {
    background-color: #3498db !important;
    color: #fff !important;
  }
}

i.bg-blue2 {
  &::before {
    background-color: #3498db !important;
    color: #fff !important;
  }
}

.bg-blue2.form-control {
  &:focus {
    background-color: #3498db !important;
    color: #fff !important;
  }
}

.bg-primary {
  &:not(i) {
    background-color: #319DB5 !important;
    color: #fff !important;
  }
}

i.bg-primary {
  &::before {
    background-color: #319DB5 !important;
    color: #fff !important;
  }
}

.bg-primary.form-control {
  &:focus {
    background-color: #319DB5 !important;
    color: #fff !important;
  }
}

.background-primary {
  &:not(i) {
    background-color: #319DB5;
    color: #fff;
  }
}

i.background-primary {
  &::before {
    background-color: #319DB5;
    color: #fff;
  }
}

.background-primary.form-control {
  &:focus {
    background-color: #319DB5;
    color: #fff;
  }
}

.bg-primary-dark {
  &:not(i) {
    background-color: #164954;
    color: #fff;
  }
}

i.bg-primary-dark {
  &::before {
    background-color: #164954;
    color: #fff;
  }
}

.bg-primary-dark.form-control {
  &:focus {
    background-color: #164954;
    color: #fff;
  }
}

.bg-blue-light {
  &:not(i) {
    background-color: #e8f4f8 !important;
    color: #5B5B5B !important;
  }
}

i.bg-blue-light {
  &::before {
    background-color: #e8f4f8 !important;
    color: #5B5B5B !important;
  }
}

.bg-blue-light.form-control {
  &:focus {
    background-color: #e8f4f8 !important;
    color: #5B5B5B !important;
  }
}

.bg-blue-dark {
  &:not(i) {
    background-color: #30364A !important;
    color: #fff !important;
  }
}

i.bg-blue-dark {
  &::before {
    background-color: #30364A !important;
    color: #fff !important;
  }
}

.bg-blue-dark.form-control {
  &:focus {
    background-color: #30364A !important;
    color: #fff !important;
  }
}

.bg-orange {
  &:not(i) {
    background-color: #F2A057 !important;
    color: #fff !important;
  }
}

i.bg-orange {
  &::before {
    background-color: #F2A057 !important;
    color: #fff !important;
  }
}

.bg-orange.form-control {
  &:focus {
    background-color: #F2A057 !important;
    color: #fff !important;
  }
}

.bg-purple {
  &:not(i) {
    background-color: #A58BD3 !important;
    color: #fff !important;
  }
}

i.bg-purple {
  &::before {
    background-color: #A58BD3 !important;
    color: #fff !important;
  }
}

.bg-purple.form-control {
  &:focus {
    background-color: #A58BD3 !important;
    color: #fff !important;
  }
}

.bg-purple2 {
  &:not(i) {
    background-color: #6E62B5 !important;
    color: #fff !important;
  }
}

i.bg-purple2 {
  &::before {
    background-color: #6E62B5 !important;
    color: #fff !important;
  }
}

.bg-purple2.form-control {
  &:focus {
    background-color: #6E62B5 !important;
    color: #fff !important;
  }
}

.bg-purple-dark {
  &:not(i) {
    background-color: #393F51 !important;
    color: #fff !important;
  }
}

i.bg-purple-dark {
  &::before {
    background-color: #393F51 !important;
    color: #fff !important;
  }
}

.bg-purple-dark.form-control {
  &:focus {
    background-color: #393F51 !important;
    color: #fff !important;
  }
}

.bg-dark {
  &:not(i) {
    background-color: #2B2E33 !important;
    color: #fff !important;
  }
}

i.bg-dark {
  &::before {
    background-color: #2B2E33 !important;
    color: #fff !important;
  }
}

.bg-dark.form-control {
  &:focus {
    background-color: #2B2E33 !important;
    color: #fff !important;
  }
}

.bg-pink {
  &:not(i) {
    background-color: #DC88E6 !important;
    color: #fff !important;
  }
}

i.bg-pink {
  &::before {
    background-color: #DC88E6 !important;
    color: #fff !important;
  }
}

.bg-pink.form-control {
  &:focus {
    background-color: #DC88E6 !important;
    color: #fff !important;
  }
}

.bg-aero {
  &:not(i) {
    background-color: #B9D5DB !important;
    color: #2C2C2C !important;
  }
}

i.bg-aero {
  &::before {
    background-color: #B9D5DB !important;
    color: #2C2C2C !important;
  }
}

.bg-aero.form-control {
  &:focus {
    background-color: #B9D5DB !important;
    color: #2C2C2C !important;
  }
}

.bg-purple-gradient {
  background: #bf9bdd;
  background: -moz-radial-gradient(center, ellipse cover, #bf9bdd 27%, #9e52dd 100%);
  background: -ms-radial-gradient(center, ellipse cover, #bf9bdd 27%, #9e52dd 100%);
  background: -o-radial-gradient(center, ellipse cover, #bf9bdd 27%, #9e52dd 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(27%, #bf9bdd), color-stop(100%, #9e52dd));
  background: -webkit-radial-gradient(center, ellipse cover, #bf9bdd 27%, #9e52dd 100%);
  background: radial-gradient(ellipse at center, #bf9bdd 27%, #9e52dd 100%);
  color: #fff;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bf9bdd', endColorstr='#9e52dd', GradientType=1);
}

.bg-opacity-20 {
  background-color: rgba(0, 0, 0, 0.2);
}

.bg-corporate {
  background-color: #31303A !important;
  color: #fff !important;
}

.bg-iris {
  background-color: #223439 !important;
  color: #fff !important;
}

i.online, i.busy, i.away, i.offline, i.turquoise {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  content: "";
  float: left;
  height: 7px;
  margin-right: 13px;
  margin-right: 8px;
  margin-top: 6px;
  width: 7px;
}

i.online {
  background-color: #18a689;
}

i.turquoise {
  background-color: #52c1df;
}

i.away {
  background-color: #ff9900;
}

i.busy {
  background-color: #c75757;
}

i.offline {
  background-color: rgba(255, 255, 255, 0.2);
}

.jqstooltip {
  background-color: rgba(0, 0, 0, 0.6);
  background: rgb(0, 0, 0) transparent;
  border: 1px solid white;
  border: none;
  color: white;
  color: white;
  font: 15px arial, san serif;
  left: 0px;
  padding: 5px;
  position: absolute;
  text-align: left;
  top: 0px;
  visibility: hidden;
  white-space: nowrap;
  z-index: 10000;
}

.jqsfield {
  color: white;
  font: 10px arial, san serif;
  text-align: left;
}

.help-block {
  font-size: 12px;
  font-weight: 500;
}

.dropdown-menu.fullwidth {
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  border-radius: 0 ! important;
}

address {
  padding-left: 15px;
}

@media (min-width: 1600px) {
  .hidden-lg {
    display: block !important;
  }
}